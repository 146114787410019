import React, { useState } from 'react'
import { GridToolbarContainer } from '@mui/x-data-grid'
import { styled } from '@material-ui/core/styles'

import { translations } from '../../../../../config'

import Button from '../../../../../components/Button'
import modalService from '../../../../../services/modalService'
import ConfirmationModal from './../../../../../components/ConfirmationModal'

const ToolbarContainer = styled(GridToolbarContainer)({
  'align-items': 'center',
  gap: '10px',
  'justify-content': 'flex-end',
  'flex-wrap': 'wrap'
})

const Toolbar = ({
  canSave,
  onSaveChanges,
  onCreateKey,
  canDelete,
  onDelete,
  onAddLanguage,
  canUpdate,
  setCanUpdate,
  resetTableState
}) => {

  const confirmCancelUpdate = () => {
    modalService.open({
      component: ConfirmationModal,
      onConfirmation: () => {
        resetTableState()
        setCanUpdate(false)
      },
      title: translations('Confirmation'),
      message: translations('Manage Translations - Confirm Cancel Update Message')
    })
  }

  return (
    <ToolbarContainer>
      {canUpdate ?
        <>
          <Button
            raised
            color="primary"
            disabled={!canSave}
            onClick={onSaveChanges}
          >
            {translations('Manage Translations - Table Save Changes Button')}
          </Button>
          <Button
            raised
            color="primary"
            onClick={() => confirmCancelUpdate()}
          >
            {translations('Manage Translations - Cancel Button')}
          </Button>
        </>
        :
        <>
          <Button
            raised
            color="primary"
            disabled={!canDelete}
            onClick={onDelete}
          >
            {translations('Manage Translations - Table Delete Button')}
          </Button>
          <Button
            raised
            color="primary"
            onClick={onCreateKey}
          >
            {translations('Manage Translations - Table Create Key Button')}
          </Button>
          <Button
            raised
            color="primary"
            onClick={onAddLanguage}
          >
            {translations('Manage Translations - Table Add Language Button')}
          </Button>
          <Button
            raised
            color="primary"
            onClick={() => setCanUpdate(true)}
          >
            {translations('Manage Translations - Update Table Button')}
          </Button>
        </>
      }
    </ToolbarContainer>
  )
}

export default Toolbar
