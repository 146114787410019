import _ from "lodash"
import QuestionIcon from '@material-ui/icons/ListAltSharp'
import RepeatableIcon from '@material-ui/icons/Repeat'
import HeadingIcon from '@material-ui/icons/TextFields'

const filterVisibleSections = (sections) =>
  _.map(_.filter(sections, 'visible'), section => ({
    ...section,
    ...section.fields ? {fields: _.filter(section.fields, 'visible')} : {}
  })
)

export const formatSections = (sections) => {
  const visibleSections = filterVisibleSections(sections)

  _.forEach(visibleSections, section => {
    section.fields.map(field => {
      if (field.fields) {
        field.fields.map(subField => {
          subField.parentId = field.id
        })
      }
    })
  })

  const nextData = _.map(visibleSections, section => {
    return updateAllIsSelectedInSection({section, isChecked: true})
  })

  return nextData
}

export const getIcon = ({ type = 'QUESTION' }) => {
  const iconProps = { fontSize: 'small' }

  switch (type) {
    case 'REPEATABLE':
      return <RepeatableIcon {...iconProps} />
    case 'HEADING':
      return <HeadingIcon {...iconProps} />
    default:
      return <QuestionIcon {...iconProps} />
  }
}

export const updateAllIsSelectedInSection = ({section, isChecked}) => {
  const newSection = {
    ...section,
     isSelected: isChecked,
     ...section.fields ? {fields: _.map(section.fields, (field => updateAllIsSelectedInSection({section: field, isChecked})))} : {}
  }

  return newSection

}

export const updateIsSelectedInFields = (fields, targetId, newValue) => {
  return _.map(fields, field => {
    if (field.id === targetId) {
      // Update field and propagate to child fields
      return updateAllIsSelectedInSection({section: field, isChecked: newValue})
    } else if (field.fields) {
      // Recurse into child fields
      return {
        ...field,
        fields: updateIsSelectedInFields(field.fields, targetId, newValue),
      }
    }
    // Return the field unchanged
    return { ...field }
  })
}

const hasFieldWithCondition = (fields, condition) => {
  return _.some(fields, field => {
      if (condition(field)) {
          return true // Field is selected
      }
      if (field.fields) {
          return hasFieldWithCondition(field.fields, condition) // Recurse into nested fields
      }
      return false // No selection found at this level
  })
}

// Check for selected fields
export const hasSelectedFields = fields => hasFieldWithCondition(fields, field => field.isSelected)
// Check for unselected fields
export const hasUnSelectedFields = fields => hasFieldWithCondition(fields, field => !field.isSelected)

