import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Input, InputLabel, FormHelperText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import style from './style'

const DatePicker = ({
  label,
  classes,
  onChange,
  meta: { touched, error } = {},
  id,
  defaultValue,
  ...rest
}) => (
  <>
    <InputLabel htmlFor={id} shrink>{label}</InputLabel>
    <Input
      id={id}
      defaultValue={defaultValue}
      type='date'
      onChange={onChange}
      className={classes.textField}
      {..._.omit(rest, ['onBlur', 'noErrorTextLabel', 'value'])}
    />
    {touched && error ? <FormHelperText error role='alert'>{error}</FormHelperText> : null}
  </>
)

DatePicker.propTypes = {
  classes: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  meta: PropTypes.shape({})
}

export default withStyles(style)(DatePicker)
