import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import { FormControl, styled } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'

import { translations } from '../../../../../config'
import Dropdown from '../../../../../components/Fields/Dropdown'
import { selectors as platformSelectors } from '../../../../../store/modules/platforms'
import { categoryOptions } from '../../../../../store/modules/formViews/selectors'
import Input from '../../../../../components/Fields/Input'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import modalService from '../../../../../services/modalService'
import ConfirmationModal from '../../../../../components/ConfirmationModal/ConfirmationModal'

import { actions as reportConfigurationActions } from '../../../../../store/modules/reportConfiguration'

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(15),
  paddingRight: theme.spacing(15),
  '@media (max-width: 814px)': {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

const TextFieldWrapper = styled('div')(({ theme }) => ({
  '& div': {
    width: '100%'
  },
  '& div label': {
    position: 'static'
  }
}))

const FlexContainer = styled('div')(({ theme, isEdit, showOptionalItemsInEdit }) => ({
  display: 'grid',
  gridGap: theme.spacing(3),
  gridTemplateRows: 'auto',
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: isEdit ? (showOptionalItemsInEdit ? '1fr 1fr 1fr' : '1fr 1fr') : '2fr 2fr 4fr',
  },
  width: '100%',
  alignItems: 'center'
}))

const FlexItem = styled('div')(({ theme }) => ({
  minWidth: 0,
}))

const OptionalItems = styled('div')(({ theme, isEdit }) => ({
  display: 'grid',
  gridTemplateColumns: isEdit ? '1fr' : '4fr 1fr 4fr',
  alignItems: 'center'
}))

const OptionalContainerText = styled('div')(({ theme }) => ({
  textAlign: 'center', 
  color: 'rgba(0, 0, 0, 0.54)', 
  fontSize: '12px'
}))


const ReportSettings = (props) => {
  const {
    fetchFormView,
    clearFormView,
    setLineListingName,
    setCategory,
    lineListingParams,
    isEdit,
    isFormChanged
  } = props

  const dispatch = useDispatch()
  const { typesOptions } = useSelector(platformSelectors.getCurrentPlatformReportManagementFilters)
  const [reportType, setReportType] = useState(null)
  const [formViews, setFormViews] = useState(null)
  const [formViewId, setFormViewId] = useState(null)
  const location = useLocation()
  const { formViewId: prePopulatedFormViewId, type: prePopulatedType } = qs.parse(location.search, { ignoreQueryPrefix: true })
  const mappedTypesOptions = _.map(typesOptions, option => ({
    label: translations(option.label),
    value: option.value
  }))

  useEffect(async() => {
    if (prePopulatedType && !reportType) {
      setReportType(prePopulatedType)  
      await fetchFormViewsByReportType(prePopulatedType)

      if (prePopulatedFormViewId && !formViewId) {
        setFormViewId(prePopulatedFormViewId)
      }
    }
  }, [])

  const confirmLostChangesModel = (callback) => {
    modalService.open({
      component: ConfirmationModal,
      title: translations('Manage Translations - Confirm Lost Changes Modal Title'),
      message: translations('Line Listing Management - Create Line Listing - Confirm Lost Changes Modal Description'),
      onConfirmation: () => callback()
    })
  }

  const onReportTypeClick = (e) => {
    const handleReportTypeClick = async () => {
      const value = e.target.value
      clearFormView()
      setFormViewId(null)
      setFormViews(null)
      setReportType(value)
      await fetchFormViewsByReportType(value)
      fetchFormView({reportType: value})
    }

    if (!isFormChanged) {
      handleReportTypeClick()
    } else {
      confirmLostChangesModel(handleReportTypeClick)
    }
  
  }

  const fetchFormViewsByReportType = async (value) => {
    const formViews = await dispatch(reportConfigurationActions.fetchFormViewsByReportType({ reportType: value }))

    if (_.size(formViews) > 0) {
      const mappedFormViews = _.map(formViews, (formView) => ({
        label: `${formView.name} - ${translations(`Version - ${formView.versionId}`)}`,
        value: formView.id
      }))
      
      setFormViews(mappedFormViews)
    }
  }

  const onNameChange = (e) => {
    const value = e.target.value
    setLineListingName(value)
  }

  const onRcClick = (e) => {
    const handleRcClick = async () => {
      const value = e.target.value
      clearFormView()
      setFormViewId(value)
      fetchFormView({reportType, formViewId: value})
    }

    if (!isFormChanged) {
      handleRcClick()
    } else {
      confirmLostChangesModel(handleRcClick)
    }
  }

  const onCategoryClick = (e) => {
    const value = e.target.value
    setCategory(value)
  }

  const showOptionalItemsInEdit = isEdit && (!!(_.get(lineListingParams, 'formViews.name') || _.get(lineListingParams, 'category')))

  if (isEdit && _.isEmpty(lineListingParams)) {
    return null
  }
  
  return (
    <Container>
      <FlexContainer isEdit={isEdit} showOptionalItemsInEdit={showOptionalItemsInEdit}>
        <FlexItem>
          <FormControl fullWidth>
            <TextFieldWrapper>
              <TextField
                label={translations('Line Listing Management - Create Line Listing - Name')}
                placeholder={translations('Line Listing Management - Create Line Listing - Name Placeholder')}
                InputLabelProps={{
                  shrink: true,
                  required: true
                }}
                onChange={onNameChange}
                value={lineListingParams?.name}
                inputProps={{ maxLength: 100 }}
              />
            </TextFieldWrapper>
          </FormControl>
        </FlexItem>

        <FlexItem>
          <FormControl fullWidth>
            {isEdit ? <Input
              label={translations('Line Listing Management - Create Line Listing - Report Type')}
              value={_.get(_.find(mappedTypesOptions, option => option.value === _.get(lineListingParams, 'formSchema.name')), 'label', '')}
              disabled
              noErrorTextLabel
            /> : <Dropdown
              label={translations('Line Listing Management - Create Line Listing - Report Type')}
              value={reportType}
              options={mappedTypesOptions}
              onChange={onReportTypeClick}
              noErrorTextLabel
              shrink
              required
              disabled={isEdit}
            />}
          </FormControl>
        </FlexItem>

        <OptionalItems isEdit={isEdit}>
          {isEdit && _.get(lineListingParams, 'formViews.name') &&
            <FlexItem>
              <FormControl fullWidth>
                <Input
                  label={translations('Line Listing Management - Create Line Listing - Report Configuration')}
                  value={_.get(lineListingParams, 'formViews.name')}
                  disabled
                  noErrorTextLabel
                />
              </FormControl>
            </FlexItem>
          }
          {!isEdit &&
            <FlexItem>
              <FormControl fullWidth>
                <Dropdown
                  label={translations('Line Listing Management - Create Line Listing - Report Configuration')}
                  value={formViewId}
                  options={formViews}
                  onChange={onRcClick}
                  noErrorTextLabel
                  shrink
                  disabled={_.size(formViews) === 0 || lineListingParams?.category}
                />
              </FormControl>
            </FlexItem>
          }

          {
            !isEdit &&
            <OptionalContainerText>
              {translations('or')}
            </OptionalContainerText>
          }
          
          {isEdit && _.get(lineListingParams, 'category') &&
            <FlexItem>
              <FormControl fullWidth>
                <Input
                  label={translations('Line Listing Management - Create Line Listing - Category')}
                  value={_.get(_.find(categoryOptions, option => option.value === _.get(lineListingParams, 'category')), 'label', '')}
                  disabled
                  noErrorTextLabel
                />
              </FormControl>
            </FlexItem>
          }
          {!isEdit &&
            <FlexItem>
              <FormControl fullWidth>
                <Dropdown
                  label={translations('Line Listing Management - Create Line Listing - Category')}
                  value={_.get(lineListingParams, 'category', null)}
                  options={categoryOptions}
                  onChange={onCategoryClick}
                  noErrorTextLabel
                  shrink
                  disabled={!reportType || formViewId}
                />
              </FormControl>
            </FlexItem>
          }
          
        </OptionalItems>

      </FlexContainer>
    </Container>
  )
}

export default ReportSettings
