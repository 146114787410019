import React, { useMemo, useState, useCallback } from 'react'
import { styled } from '@material-ui/core/styles'
import _ from 'lodash'

import { translations } from '../../../../../config'

import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import Button from '../../../../../components/Button'
import Types from '../Table/types'
import { TablePagination } from '@material-ui/core'

export const ChangeList = styled('div')({
  display: 'flex',
  'flex-direction': 'column',
  gap: '10px'
})

export const Modification = styled('pre')(({ theme }) => ({
  'background-color': theme.palette.grey['100'],
  padding: '10px',
  'border-radius': '5px',
  'white-space': 'pre-wrap'
}))

export const AdditionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  'flex-direction': 'column',
  gap: '5px',
  'background-color': theme.palette.grey['100'],
  padding: '10px',
  'border-radius': '5px'
}))

export const Addition = styled('pre')(({ theme }) => ({
  'background-color': theme.palette.success.light,
  padding: '10px',
  'border-radius': '5px',
  'white-space': 'pre-wrap'
}))

export const NewKey = styled('pre')(({ theme }) => ({
  'background-color': theme.palette.success.light,
  padding: '10px',
  'border-radius': '5px',
  'white-space': 'pre-wrap',
  margin: 0
}))

export const DuplicateKey = styled('pre')(({ theme }) => ({
  'background-color': theme.palette.error.main,
  color: 'white',
  padding: '10px',
  'border-radius': '5px',
  'white-space': 'pre-wrap',
  margin: 0
}))

const ActionContainer = styled('div')({
  'margin-top': '20px',
  display: 'flex',
  gap: '10px'
})

const CloseButton = styled(Button)({
  'margin-left': 'auto'
})

const PaginationChanges = ({ content, header, changeType }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const count = _.size(content)

  const onChangePage = useCallback((event, page) => {
    setPage(page)
  }, [setPage])

  const onChangeRowsPerPage = useCallback((e) => {
    const limit = e.target.value
    setRowsPerPage(limit)
    setPage(0)
  }, [setRowsPerPage, setPage])
  
  let chunkedContent = _.chunk(content, rowsPerPage)[page]
  let returnedContent
  switch (changeType) {
    case 'modifications': 
      returnedContent = chunkedContent.map(({ id, type, ...modification }) => {
        switch (type) {
          case Types.TRANSLATION: {
            const { value, key, initial, languageName } = modification

            return (
              <Modification key={id}>{
                translations(
                  'Manage Translations - Save Changes TRANSLATION',
                  { 
                    languageName, 
                    key: key.replace(/{{/g, '%%'), 
                    initial: initial.replace(/{{/g, '%%'), 
                    value: value.replace(/{{/g, '%%')
                  }
                ) 
                .replace(/%%/g, '{{')
              }</Modification>
            )
          }
          case Types.KEY: {
            const { value, initial } = modification

            return (
              <Modification key={id}>{
                translations(
                  'Manage Translations - Save Changes KEY',
                  { 
                    initial: initial.replace(/{{/g, '%%'), 
                    value: value.replace(/{{/g, '%%')
                  }
                ) 
                .replace(/%%/g, '{{')
              }</Modification>
            )
          }
          default:
            return null
        }
      })
      break
    case 'additions': 
      returnedContent = chunkedContent.map(([key, langs]) => (
        <AdditionContainer key={key}>
          <h4>{key}</h4>
          {_.map(langs, ({ keyId, languageId, languageName, value }) => (
            <Addition key={`${keyId}-${languageId}`}>{
              translations(
                'Manage Translations - Save Changes NEW_TRANSLATION',
                { 
                  languageName,
                  value: value.replace(/{{/g, '%%')
                }
              ) 
              .replace(/%%/g, '{{')
            }</Addition>
          ))}
        </AdditionContainer>
      ))
      break
    case 'newKeys':
      returnedContent = chunkedContent.map(({ value, id }) => (
        <NewKey key={id}>{value}</NewKey>
      ))
      break
    default:
      break
  }

  return <>
    <ChangeList>
      <h3>{header}</h3>
      {returnedContent}
    </ChangeList>
    <TablePagination
      style={{display: 'inline-table'}}
      count={count}
      page={page}
      rowsPerPage={rowsPerPage}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      rowsPerPageOptions={[10, 100, 250, 500]}
    />

  </>
}

const DuplicateKeys = ({ duplicateKeys }) => (
  <ChangeList>
    <p>{translations('Manage Translations - Changes Modal Duplicate Keys Description')}</p>
    {duplicateKeys.map((duplicateKey) => (
      <DuplicateKey key={duplicateKey}>{duplicateKey}</DuplicateKey>
    ))}
  </ChangeList>
)

export const Changes = ({ additions, modifications, newKeys, duplicateKeys }) => {
  const hasAdditions = !!Object.keys(additions).length
  const hasModifications = !!modifications.length
  const hasNewKeys = !!newKeys.length

  return <>
    {duplicateKeys && (
      <DuplicateKeys duplicateKeys={duplicateKeys} />
    )}

    {hasModifications && (
      <PaginationChanges
        header={translations('Manage Translations - Save Changes Modifications Header')}
        content={modifications}
        changeType={"modifications"}
      />
    )}

    {hasAdditions && (
      <PaginationChanges
        header={translations('Manage Translations - Save Changes Additions Header')}
        content={Object.entries(additions)}
        changeType={"additions"}
      />
    )}

    {hasNewKeys && (
      <PaginationChanges
        header={translations('Manage Translations - Save Changes New Keys Header')}
        content={newKeys}
        changeType={"newKeys"}
      />
    )}
  </>
}

const SaveChangesModal = ({ changes = [], duplicateKeys, onSubmitTranslationChanges, onClose }) => {
  const { additions, modifications, newKeys } = useMemo(() => {
    const additions = _.groupBy(changes.filter(({ type }) => type === Types.NEW_TRANSLATION), 'key')
    const modifications = changes.filter(({ type }) => [Types.TRANSLATION, Types.KEY].includes(type))
    const newKeys = changes.filter(({ type }) => type === Types.NEW_KEY)

    return { additions, modifications, newKeys }
  }, [changes])

  return (
    <BasicModalOverlay title={translations(duplicateKeys ? 'Manage Translations - Changes Modal Duplicate Keys Title' : 'Confirm modifications & additions')}>
      <Changes additions={additions} modifications={modifications} newKeys={newKeys} duplicateKeys={duplicateKeys} />
      <ActionContainer>
        <CloseButton onClick={() => onClose()} raised color="primary">{translations('Manage Translations - Save Changes Close Button')}</CloseButton>
        {!duplicateKeys && (
          <Button onClick={() => onSubmitTranslationChanges(changes)} raised color="primary">{translations('Manage Translations - Save Changes Button')}</Button>
        )}
      </ActionContainer>
    </BasicModalOverlay>
  )
}

export default SaveChangesModal
