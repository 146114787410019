import { createSelector } from 'reselect'
import _ from 'lodash'
import { PENDING } from '../../middleware/redux-promise'
import { defaultTranslations } from '../../../config'

export const getIsLoading = state => _.get(state, 'translations.setStatus') === PENDING || _.get(state, 'translations.fetchStatus') === PENDING
export const getDefaultTranslations = state => defaultTranslations
export const getTranslations = state => _.get(state, 'translations.translations')

export const getLanguages = createSelector(
  [getTranslations],
  (translations) => {
    return _.keys(translations)
  }
)

export const getCurrentOrganisationLanguagesAsOptions = createSelector(
  [state => _.get(state, 'translations.languages', [])],
  (languages) => {
    if (_.isEmpty(languages)) return []
    return languages.map(t => {
      const label = _.get(t, 'name')
      const value = _.get(t, 'id')
      const isoCode = _.get(t, 'isoCode')
      return { label, value, isoCode }
    })
  }
)

export const getSearchedTranslations = state => _.get(state, 'translations.searchedTranslations')
export const getTranslationsAuditTrail = state => _.get(state, 'translations.auditTrail')
export const getTotalTranslations = state => _.get(state, 'translations.totalTranslations')
export const getTranslationDifferences = state => _.get(state, 'translations.differences')
export const getOffset = state => _.get(state, 'translations.offset')
export const getSearchValue = state => _.get(state, 'translations.searchValue')

export const getTranslationKeyTags = state => _.get(state, 'translations.tags.keyTags')
export const getTranslationKeyTagOptions = createSelector(
  [getTranslationKeyTags],
  (keyTags) => {
    return _.map(keyTags, tag => ({
      label: tag.name,
      value: tag.id
    }))
  }
)

export const getIsAfterSave = state => _.get(state, 'translations.isAfterSave', false)

