import React, { useState, useEffect } from 'react'
import { translations } from '../../../../config'
import { useDispatch } from 'react-redux'
import { styled } from '@material-ui/core/styles'
import _ from 'lodash'
import { push as routerPush, goBack } from 'connected-react-router'
import { hasSelectedFields } from './helpers'
import digitalStoreSdk from '../../../../digitalStoreSdk'
import { useParams, useLocation } from 'react-router-dom'
import qs from 'qs'

import LineListingHeader from '../LineListingHeader'
import ReportSettings from './ReportSettings'
import ReportSections from './ReportSections'
import { actions as formViewActions} from '../../../../store/modules/formViews'
import { actions as lineListingActions} from '../../../../store/modules/lineListings'
import { updateAllIsSelectedInSection, updateIsSelectedInFields, formatSections } from './helpers'

import Button from '../../../../components/Button'
import BottomBar from '../../../../components/BottomBar'
import FormSchemaLibrary from '@redant/mhra-form-schema-library'
import toastService from '../../../../services/toastService'
import modalService from '../../../../services/modalService'
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal'
import MoreMenu from '../../../../components/MoreMenu/MoreMenu'

const ReportSectionContainer = styled('div')(({ theme }) => ({
  margin: '0px auto',
  maxWidth: '1200px',
}))

export const NoFieldsText = styled('div')(({ theme }) => ({
  width: '100%',
  marginTop: 30,
  textAlign: 'center',
  fontSize: 19,
  fontWeight: 'bold',
  textTransform: 'uppercase'
}))

const LineListingEditor = () => {
  const dispatch = useDispatch()
  const [lineListingParams, setLineListingParams] = useState({})
  const [isFormChanged, setIsFormChanged] = useState(false)
  const { viewJSON, name } = lineListingParams

  const { id: lineListingId } = useParams()
  const isEdit = lineListingId && lineListingId !== 'new'
  const location = useLocation()
  const { formViewId: prePopulatedFormViewId, type: prePopulatedType } = qs.parse(location.search, { ignoreQueryPrefix: true })

  useEffect(async () => {
    if (isEdit) {
      const result = await digitalStoreSdk.lineListings.fetchLineListingById({id: lineListingId})
      if (result) {
        setLineListingParams(result.result)
      }
    }
  }, [lineListingId])

  useEffect(() => {
    if (!isEdit && prePopulatedFormViewId && prePopulatedType) {
      fetchFormView({formViewId: prePopulatedFormViewId, reportType: prePopulatedType})
    }
  }, [])

  const clearFormView = () => {
    const updatedLineListingParams = {
      ...lineListingParams,
      viewJSON: null,
      category: null
    }
    setLineListingParams(updatedLineListingParams)
  }
  
  const fetchFormView = async (params) => {
    const {reportType, formViewId} = params
    let sections
    if (formViewId) {
      const formView = await dispatch(formViewActions.fetchFormViewById({ id: formViewId }))      
      sections = _.get(formView, ['formViewVersions', 0, 'viewJSON'])
    } else {
      try {
        const formSchemaLibrary = new FormSchemaLibrary()
        const standardBase = formSchemaLibrary.getDefaultView(reportType, null)
        sections = standardBase.sections
      } catch (error) {
        toastService.action({
          type: 'error',
          message: translations('Line Listing Management - Create Line Listing - No schema found'),
          autoHideDuration: 6000
        })
      }
    }

    const formattedData = formatSections(sections)
    setLineListingParams({...lineListingParams, ...params, viewJSON: formattedData})
    setIsFormChanged(false)
  }

  const onListNameChange = (value) => {
    setLineListingParams({...lineListingParams, name: value})
  }

  const onCategoryChange = (value) => {
    setLineListingParams({...lineListingParams, category: value})
  }

  const onSelectSection = (e, sectionId) => {
    const isChecked = e.target.checked
    const updatedSections = viewJSON.map((section) => {
      if (section.id === sectionId) {
          return updateAllIsSelectedInSection({section, isChecked})
      }
      return section
    })
    setIsFormChanged(true)
    setLineListingParams({...lineListingParams, viewJSON: updatedSections})
  }

  const onSelectField = ({isChecked, fieldId}) => {
    const updatedSections = _.cloneDeep(viewJSON)
    _.forEach(updatedSections, section => {
      section.fields = updateIsSelectedInFields(section.fields, fieldId, isChecked)      
    })
    setIsFormChanged(true)
    setLineListingParams({...lineListingParams, viewJSON: updatedSections})
  }

  const createLineListing = async () => {
    try {
      await dispatch(lineListingActions.createLineListing(lineListingParams))

      toastService.action({
        type: 'success',
        message: translations('Line Listing Management - Create Line Listing - Success', {name}),
        autoHideDuration: 6000
      })
  
      dispatch(routerPush('/monitoring-and-analytics/line-listing'))
    } catch (error) {
      toastService.action({
        type: 'error',
        message: translations('Line Listing Management - Create Line Listing - Failed'),
        autoHideDuration: 6000
      })
    }
  }

  const editLineListing = async () => {
    try {
      await digitalStoreSdk.lineListings.updateLineListing({id: lineListingId, viewJSON, name })

      toastService.action({
        type: 'success',
        message: translations('Line Listing Management - Edit Line Listing - Success', {name}),
        autoHideDuration: 6000
      })
  
      dispatch(routerPush('/monitoring-and-analytics/line-listing'))
    } catch (error) {
      toastService.action({
        type: 'error',
        message: translations('Line Listing Management - Edit Line Listing - Failed'),
        autoHideDuration: 6000
      })
    }
  }

  const onBackClick = () => {
    if (viewJSON) {
      modalService.open({
        component: ConfirmationModal,
        title: translations('Line Listing Management - Create Line Listing - cancel changes Header'),
        message: translations('Line Listing Management - Create Line Listing - cancel changes Message'),
        onConfirmation: () => dispatch(goBack())
      })
    } else {
      dispatch(goBack())
    }
  }

  const deleteLineListing = async() => {
    try {
      const result = await digitalStoreSdk.lineListings.deleteLineListing({id: lineListingId})
      toastService.action({
        type: 'success',
        message: translations('Line Listing Management - Delete Line Listing - Success'),
        autoHideDuration: 6000
      })  
    } catch (error) {
      console.error(error)
      toastService.action({
        type: 'error',
        message: translations('Line Listing Management - Delete Line Listing - Failed'),
        autoHideDuration: 6000
      })  
    } finally {
      dispatch(goBack())
    }
  }

  const allSectionsUnSelected = !hasSelectedFields(viewJSON)

  return (
    <>
      <LineListingHeader
        headerText={isEdit ? translations('Line Listing Management - Edit Line Listing'): translations('Line Listing Management - Create Line Listing')}
        onBackClick={onBackClick}
        rightContent={isEdit && 
          <MoreMenu id={'announcementId'} options={[
            {
              label: translations('Delete'),
              onClick: () => modalService.open({
                component: ConfirmationModal,
                title: translations('Line Listing Management - Delete Line Listing - Delete Line Listing Header'),
                message: translations('Line Listing Management - Delete Line Listing - Delete Line Listing Message', {name}),
                onConfirmation: deleteLineListing
              })
            }
          ]} />
        }
      />
      <ReportSettings
        fetchFormView={fetchFormView}
        clearFormView={clearFormView}
        setLineListingName={onListNameChange}
        setCategory={onCategoryChange}
        lineListingParams={lineListingParams}
        isEdit={isEdit}
        isFormChanged={isFormChanged}
      />
      {viewJSON ?
        <ReportSectionContainer>
          {_.map(viewJSON, (section) => {
            return <ReportSections
              section={section}
              onSelectSection={onSelectSection}
              onSelectField={onSelectField}
              key={section.id}
            />
          })}
        </ReportSectionContainer>:
        <NoFieldsText>{translations('Line Listing Management - Create Line Listing - Please select fields above')}</NoFieldsText>
      }
      {!_.isEmpty(viewJSON) &&
        <BottomBar>
          <Button
            buttonType='primary'
            raised
            aria-label={translations('Line Listing Management - Create Line Listing - Create')}
            disabled={!name || allSectionsUnSelected}
            onClick={isEdit ? editLineListing : createLineListing}
          >
            {isEdit ? 
              translations('Line Listing Management - Edit Line Listing - Edit'):
              translations('Line Listing Management - Create Line Listing - Create')
            }
          </Button>
        </BottomBar>
      }
    </>
  )
}

export default LineListingEditor
