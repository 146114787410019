import _ from 'lodash'
import * as validators from '../../../validators'
import { drugAdministrationRoutesR3, countryCodes, drugDosageUnits } from '../../../config'
import moment from 'moment'

import {
  getFormatForDate
} from '../formHelpers'

const dateFormats = {
  yyyymmdd: '102',
  yyyymm: '610',
  yyyy: '602'
}

const getMHRAFormatForDate = value => {
  return getFormatForDate(value, dateFormats)
}

const initialFieldMap = {
  medicinalProduct: {
    group: 'medicine',
    field: 'medicinalproduct'
  }
}

const formSchema = {
  admin: {
    title: 'Admin',
    initialOpen: true,
    schema: [
      {
        id: 'canSaveDraft',
        field: 'Input',
        computedValue: values => values.safetyreportid && values.messagenumb,
        props: {
          type: 'hidden',
          name: 'canSaveDraft',
          label: 'Can save draft',
          required: false
        }
      },
      {
        id: 'safetyreportid',
        field: 'Input',
        props: {
          shrink: true,
          name: 'safetyreportid',
          label: `Sender's Safety Report Unique Identifier`,
          required: true,
          validate: [
            validators.maxLength(100, 'Field'),
            validators.safetyReportIdFormat
          ]
        }
      },
      {
        id: 'messagetype',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'messagetype',
          required: true,
          label: 'Message Type',
          options: ['ICHICSR']
        }
      },
      {
        id: 'messagenumb',
        field: 'Input',
        props: {
          shrink: true,
          name: 'messagenumb',
          label: 'Message Number',
          validate: [validators.maxLength(100, 'Field')],
          required: true
        }
      },
      {
        id: 'transmissiondate',
        field: 'Date',
        computedValue: () => moment().format('YYYYMMDD000000'),
        props: {
          disabled: true,
          shrink: true,
          name: 'transmissiondate',
          label: 'Date of this transmission',
          required: true,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD000000'])],
          helperText: 'YYYY/MM/DD'
        }
      },
      {
        id: 'reporttype',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'reporttype',
          label: 'Type of report',
          options: [
            { value: '1', label: 'Spontaneous' },
            { value: '2', label: 'Report from study' },
            { value: '3', label: 'Other' },
            { value: '4', label: 'Not available to sender (unknown)' }
          ],
          required: true
        }
      },
      {
        id: 'receivedate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'receivedate',
          label: 'Date report was first received from source',
          required: true,
          validate: [
            validators.isAcceptedDateFormat(['YYYYMMDD']),
            validators.isDateAfterOrEqual(
              'reaction',
              'Reaction start date',
              'reactionstartdate'
            )
          ],
          helperText: 'YYYY/MM/DD'
        }
      },
      {
        id: 'receiptdate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'receiptdate',
          label:
            'Date of receipt of the most recent information for this report',
          required: true,
          validate: [
            validators.isAcceptedDateFormat(['YYYYMMDD']),
            validators.isDateAfterOrEqual(
              'reaction',
              'Reaction start date',
              'reactionstartdate'
            ),
            validators.dateTimeNotInFuture('receiptdateformat', dateFormats)
          ],
          helperText: 'YYYY/MM/DD',
          startYear: 1920
        }
      },
      {
        id: 'fulfillexpeditecriteria',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'fulfillexpeditecriteria',
          label: 'Does this case fulfill the local criteria for an expedited report?',
          options: [{ value: true, label: 'Yes' }, { value: false, label: 'No' }],
          required: true
        }
      },
      {
        id: 'authoritynumb',
        field: 'Input',
        computedEditable: values => !values.companynumb,
        props: {
          shrink: true,
          name: 'authoritynumb',
          label: `Regulatory authority's case report number`,
          required: true,
          validate: [
            validators.maxLength(100, 'Field'),
            validators.safetyReportIdFormat
          ]
        }
      },
      //new R3 field
      {
        id: 'firstsenderofcase',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'firstsenderofcase',
          label: 'First sender of case',
          options: [{ value: '1', label: 'Regulator' }, { value: '2', label: 'Other' }],
          required: true
        }
      },
      //new R3 field
      {
        id: 'othercases',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'othercases',
          label: 'Other Case Identifiers in Previous Transmissions',
          options: [{ value: true, label: 'Yes' }, { value: false, label: 'No' }],
          required: true
        }
      },
      //new R3 field
      {
        id: 'othercaseidentifiers',
        field: 'Repeatable',
        computedRequired: values => values.othercases === true,
        props: {
          shrink: true,
          name: 'othercaseidentifiers',
          label: 'Other Case Identifiers',
          repeatableLabel: ['othercasesourceid'],
          schema: [
            {
              id: 'othercasesourceid',
              field: 'Input',
              computedRequired: values => values.othercases === true,
              props: {
                shrink: true,
                name: 'othercasesourceid',
                label: 'Other Case Source Id',
                required: false,
                validate: [validators.maxLength(50, 'Field')]
              }
            },
            {
              id: 'othercaseid',
              field: 'Input',
              computedRequired: values => values.othercases === true,
              props: {
                shrink: true,
                name: 'othercaseid',
                label: 'Other Case Id',
                required: false,
                validate: [validators.maxLength(50, 'Field')]
              }
            }
          ]
        }
      }
    ]
  },
  duplicates: {
    title: 'Duplicates / Nullifications',
    layout: [
      // 'duplicate:12',
      // 'reportduplicate:12'
    ],
    schema: [
      {
        id: 'casenullification',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'casenullification',
          label: 'Report Nullification',
          options: [
            { value: '1', label: 'Nullification' },
            { value: '2', label: 'Amendment' }
          ],
          required: false
        }
      },
      {
        id: 'nullificationreason',
        field: 'Input',
        computedRequired: (values) => values.casenullification === '1',
        props: {
          shrink: true,
          name: 'nullificationreason',
          label: 'Reason for nullification',
          required: false,
          validate: [validators.maxLength(200, 'Field')]
        }
      },
      {
        id: 'linkedreport',
        field: 'Repeatable',
        computedEditable: (values) => values.duplicate === '1',
        props: {
          shrink: true,
          name: 'linkedreport',
          label: 'Linked Reports',
          repeatableLabel: ['linkreportnumb'],
          schema: [
            {
              id: 'linkreportnumb',
              field: 'Input',
              props: {
                shrink: true,
                name: 'linkreportnumb',
                label: 'Linked report number',
                required: false,
                validate: [validators.maxLength(100, 'Field')]
              }
            }
          ]
        }
      }
    ]
   },
  reporter: {
    title: 'Reporter',
    layout: [
      'primarysource:12'
    ],
    schema: [
      {
        id: 'primarysource',
        field: 'Repeatable',
        props: {
          shrink: true,
          name: 'primarysource',
          label: 'Reporter(s)',
          repeatableLabelPlaceholder: 'Reporter',
          repeatableLabel: ['reportergivename', 'reporterfamilyname'],
          repeatableSeparator: ' ',
          required: true,
          schema: [
            //new R3 field
            {
              id: 'primarysourceforreg',
              field: 'Input',
              computedValue: values => values.primarysource || 1,
              props: {
                type: 'hidden',
                name: 'primarysourceforreg',
                label: 'Primary Source For Regulatory Purposes',
                required: false
              }
            },
            {
              id: 'reportertitle',
              field: 'Input',
              props: {
                shrink: true,
                name: 'reportertitle',
                label: 'Reporter Title',
                required: false,
                validate: [validators.maxLength(50, 'Field')]
              }
            },
            {
              id: 'reportergivename',
              field: 'Input',
              props: {
                shrink: true,
                name: 'reportergivename',
                label: 'Reporter given name',
                required: false,
                validate: [validators.maxLength(60, 'Field')]
              }
            },
            {
              id: 'reporterfamilyname',
              field: 'Input',
              props: {
                shrink: true,
                name: 'reporterfamilyname',
                label: 'Reporter family name',
                required: false,
                validate: [validators.maxLength(60, 'Field')]
              }
            },
            {
              id: 'reporterorganization',
              field: 'Input',
              props: {
                shrink: true,
                name: 'reporterorganization',
                label: 'Reporter organization',
                required: false,
                validate: [validators.maxLength(60, 'Field')]
              }
            },
            {
              id: 'reporterstreet',
              field: 'Input',
              props: {
                shrink: true,
                name: 'reporterstreet',
                label: 'Reporter street',
                required: false,
                validate: [validators.maxLength(100, 'Field')]
              }
            },
            {
              id: 'reportercity',
              field: 'Input',
              props: {
                shrink: true,
                name: 'reportercity',
                label: 'Reporter city',
                required: false,
                validate: [validators.maxLength(35, 'Field')]
              }
            },
            {
              id: 'reporterstate',
              field: 'Input',
              props: {
                shrink: true,
                name: 'reporterstate',
                label: 'Reporter state or province',
                required: false,
                validate: [validators.maxLength(40, 'Field')]
              }
            },
            {
              id: 'reporterpostcode',
              field: 'Input',
              props: {
                shrink: true,
                name: 'reporterpostcode',
                label: 'Reporter postcode',
                required: false,
                validate: [validators.maxLength(15, 'Field')]
              }
            },
            {
              id: 'reportercountry',
              field: 'Dropdown',
              computedRequired: (values) => values.primarysourceforreg === '1',
              props: {
                shrink: true,
                name: 'reportercountry',
                label: 'Reporter country code',
                autocomplete: true,
                options: countryCodes,
                required: false
              }
            },
            {
              id: 'qualification',
              field: 'Dropdown',
              computedRequired: (values) => values.primarysourceforreg === '1',
              computedValue: (values) => !values.qualification && 'UNK',
              props: {
                shrink: true,
                name: 'qualification',
                noNullOption: true,
                label: 'Reporter qualification',
                options: [
                  { value: '1', label: 'Physician' },
                  { value: '2', label: 'Pharmacist' },
                  { value: '3', label: 'Other Health Professional' },
                  { value: '4', label: 'Lawyer' },
                  { value: '5', label: 'Consumer or other non health professional' },
                  { value: 'UNK', label: 'Unknown' },

                ],
                required: false
              }
            },
            {
              id: 'literaturereference',
              field: 'Input',
              props: {
                shrink: true,
                name: 'literaturereference',
                label: 'Literature reference(s)',
                required: false,
                validate: [validators.maxLength(500, 'Field')]
              }
            },
            {
              id: 'sponsorstudynumb',
              field: 'Input',
              computedRequired: (values) => values.observestudytype === '1',
              props: {
                shrink: true,
                name: 'sponsorstudynumb',
                label: 'Sponsor study number',
                required: false,
                validate: [validators.maxLength(50, 'Field')]
              }
            },
            {
              id: 'observestudytype',
              field: 'Dropdown',
              computedRequired: values => values.reporttype === '2',
              props: {
                shrink: true,
                name: 'observestudytype',
                label: 'Study type',
                options: [
                  { value: '1', label: 'Clinical trials' },
                  { value: '2', label: 'Individual patient use (e.g. ‘compassionate use’ or ‘named patient basis’)' },
                  { value: '3', label: 'Other studies (e.g. pharmacoepidemiology, pharmacoeconomics, intensive monitoring)' }
                ],
                required: false
              }
            }
          ]
        }
      }
    ]
  },
  patient: {
    title: 'Patient',
    schema: [
      {
        id: 'patientinitial',
        field: 'Input',
        props: {
          shrink: true,
          name: 'patientinitial',
          label: 'Patient initials',
          required: true,
          validate: [validators.maxLength(10, 'Field')]
        }
      },
      {
        id: 'patientgpmedicalrecordnumb',
        field: 'Input',
        props: {
          shrink: true,
          name: 'patientgpmedicalrecordnumb',
          label: 'Patient record number',
          required: false,
          validate: [validators.maxLength(20, 'Field')]
        }
      },
      {
        id: 'patientonsetage',
        field: 'Input',
        computedRequired: values => {
          return !!values.patientonsetageunit
        },
        props: {
          shrink: true,
          name: 'patientonsetage',
          label: 'Patient age at time of onset of reaction/event',
          required: false,
          validate: [validators.maxNumericLength(5, 'Field')]
        }
      },
      {
        id: 'patientonsetageunit',
        field: 'Dropdown',
        computedRequired: values => {
          return !!values.patientonsetage
        },
        props: {
          shrink: true,
          name: 'patientonsetageunit',
          label: 'Patient age unit',
          required: false,
          options: [
            { value: '{decade}', label: '{Decade}' },
            { value: 'a', label: 'Year' },
            { value: 'mo', label: 'Month' },
            { value: 'wk', label: 'Week' },
            { value: 'd', label: 'Day' },
            { value: 'h', label: 'Hour' }
          ]
        }
      },
      {
        id: 'patientagegroup',
        field: 'Dropdown',
        computedRequired: values => {
          return !!values.patientonsetage
        },
        props: {
          shrink: true,
          name: 'patientagegroup',
          label: 'Patient age group',
          required: false,
          options: [
            { value: '0', label: 'Foetus' },
            { value: '1', label: 'Neonate (Preterm and Term newborns)' },
            { value: '2', label: 'Infant' },
            { value: '3', label: 'Child' },
            { value: '4', label: 'Adolescent' },
            { value: '5', label: 'Adult' },
            { value: '6', label: 'Elderly' }
          ]
        }
      },
      {
        id: 'patientweight',
        field: 'Input',
        props: {
          shrink: true,
          name: 'patientweight',
          label: 'Patient weight (kg)',
          required: false,
          validate: [validators.maxNumericLength(6, 'Field')]
        }
      },
      {
        id: 'patientheight',
        field: 'Input',
        props: {
          shrink: true,
          name: 'patientheight',
          label: 'Patient height (cm)',
          required: false,
          validate: [validators.maxNumericLength(3, 'Field')]
        }
      },
      {
        id: 'patientsex',
        field: 'Dropdown',
        computedValue: (values) => !values.patientsex && 'NASK',
        props: {
          shrink: true,
          name: 'patientsex',
          label: 'Patient sex',
          required: false,
          noNullOption: true,
          options: [
            { value: '1', label: 'Male' },
            { value: '2', label: 'Female' },
            { value: 'NASK', label: 'Not asked'}
          ]
        }
      },
      {
        id: 'gestationperiod',
        field: 'Input',
        computedHidden: (values) => values.patientsex !== '2',
        props: {
          hideIfEmpty: true,
          shrink: true,
          name: 'gestationperiod',
          label: 'Gestation period',
          required: false,
          validate: [validators.maxNumericLength(3, 'Field')]
        }
      },
      {
        id: 'gestationperiodunit',
        field: 'Dropdown',
        computedHidden: (values) => values.patientsex !== '2',
        props: {
          shrink: true,
          hideIfEmpty: true,
          name: 'gestationperiodunit',
          label: 'Gestation period unit',
          required: false,
          options: [
            { value: 'mo', label: 'Month' },
            { value: 'wk', label: 'Week' },
            { value: 'd', label: 'Day' },
            { value: '{trimester}', label: '{Trimester}' }
          ]
        }
      },
      {
        id: 'patientlastmenstrualdate',
        field: 'Date',
        computedHidden: (values) => values.patientsex !== '2',
        props: {
          shrink: true,
          hideIfEmpty: true,
          name: 'patientlastmenstrualdate',
          label: 'Last menstrual period date',
          required: false,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
          helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
        }
      }
    ]
  },
  patientHistory: {
    title: 'Patient History',
    layout: [
      'medicalhistoryepisode:12',
      'patientmedicalhistorytext:12',
      'patientpastdrugtherapy:12'
    ],
    schema: [
      {
        id: 'medicalhistoryepisode',
        field: 'Repeatable',
        props: {
          shrink: true,
          name: 'medicalhistoryepisode',
          label: 'Medical history episode',
          repeatableLabelPlaceholder: 'Episode',
          repeatableLabel: ['patientepisodename'],
          required: false,
          schema: [
            {
              id: 'patientepisodenamemeddraversion',
              field: 'Input',
              computedValue: (values, parentValues, orgDetails) => orgDetails.meddraVersion,
              props: {
                type: 'hidden',
                shrink: true,
                name: 'patientepisodenamemeddraversion',
                label: 'Patient medical history MedDRA Version',
                required: true
              }
            },
            {
              id: 'patientepisodename',
              field: 'MedDRALookupInput',
              props: {
                shrink: true,
                name: 'patientepisodename',
                label: 'Patient medical history',
                required: false,
              }
            },
            {
              id: 'patientmedicalstartdate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'patientmedicalstartdate',
                label: 'Patient medical history start date',
                required: false,
                validate: [
                  validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']),
                  validators.isDateBeforeOrEqual('patientmedicalenddate', 'Patient medical history end date'),
                  validators.dateTimeNotInFuture('patientmedicalstartdateformat', dateFormats)
                ],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
                startYear: 1920
              }
            },
            {
              id: 'patientmedicalcontinue',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'patientmedicalcontinue',
                label: 'Patient medical history continuing?',
                required: false,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: '2', label: 'No' },
                ]
              }
            },
            {
              id: 'patientmedicalenddate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'patientmedicalenddate',
                label: 'Patient medical history end date',
                required: false,
                validate: [
                  validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']),
                  validators.isDateAfterOrEqual('patientmedicalstartdate',
                    'Patient medical history start date'),
                  validators.dateTimeNotInFuture('patientmedicalenddateformat', dateFormats)
                ],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
                startYear: 1920
              }
            },
            {
              id: 'patientmedicalcomment',
              field: 'Input',
              props: {
                shrink: true,
                name: 'patientmedicalcomment',
                label: 'Patient medical history comment',
                validate: [validators.maxLength(100, 'Field')],
                required: false
              }
            }
          ]
        }
      },
      {
        id: 'patientmedicalhistorytext',
        field: 'Input',
        computedRequired: values => {
          return !!values.patientdrugstartdate || !!values.patientdrugenddate
        },
        props: {
          shrink: true,
          name: 'patientmedicalhistorytext',
          label: 'Patient medical history text',
          validate: [validators.maxLength(10000, 'Field')],
          required: false
        }
      },
      {
        id: 'patientpastdrugtherapy',
        field: 'Repeatable',
        props: {
          shrink: true,
          name: 'patientpastdrugtherapy',
          label: 'Patient past drug therapy',
          repeatableLabelPlaceholder: 'Therapy',
          repeatableLabel: ['patientdrugname'],
          required: false,
          schema: [
            {
              id: 'patientdrugname',
              field: 'DrugLookupInput',
              props: {
                required: true,
                shrink: true,
                name: 'patientdrugname',
                label: 'Past drug name',
                validate: [validators.maxLength(250, 'Field')]
              }
            },
            {
              id: 'patientdrugstartdate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'patientdrugstartdate',
                label: 'Past drug start date',
                required: false,
                validate: [
                  validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']),
                  validators.isDateBeforeOrEqual('patientdrugenddate', 'Past drug end date'),
                  validators.dateTimeNotInFuture('patientdrugstartdateformat', dateFormats)
                ],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
                startYear: 1920
              }
            },
            {
              id: 'patientdrugenddate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'patientdrugenddate',
                label: 'Past drug end date',
                required: false,
                validate: [
                  validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']),
                  validators.isDateAfterOrEqual('patientdrugstartdate', 'Past drug start date'),
                  validators.dateTimeNotInFuture('patientdrugenddateformat', dateFormats)
                ],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
                startYear: 1920
              }
            },
            {
              id: 'patientindicationmeddraversion',
              field: 'Input',
              computedValue: (values, parentValues, orgDetails) => orgDetails.meddraVersion,
              props: {
                type: 'hidden',
                shrink: true,
                name: 'patientindicationmeddraversion',
                label: 'Past drug indication MedDRA Version',
                required: true
              }
            },
            {
              id: 'patientdrugindication',
              field: 'MedDRALookupInput',
              props: {
                shrink: true,
                name: 'patientdrugindication',
                label: 'Past drug indication',
                required: false,
              }
            },
            {
              id: 'patientdrgreactionmeddraversion',
              field: 'Input',
              computedValue: (values, parentValues, orgDetails) => orgDetails.meddraVersion,
              props: {
                type: 'hidden',
                shrink: true,
                name: 'patientdrgreactionmeddraversion',
                label: 'Past drug reaction MedDRA Version',
                required: true
              }
            },
            {
              id: 'patientdrugreaction',
              field: 'MedDRALookupInput',
              props: {
                shrink: true,
                name: 'patientdrugreaction',
                label: 'Past drug reaction',
                required: false,
              }
            }
          ]
        }
      }
    ]
  },
  death: {
    title: 'Death',
    layout: [
      'patientdeathdate:12',
      'patientdeathcause:12',
      'patientautopsyyesno:12',
      'patientautopsy:12'
    ],
    schema: [
      {
        id: 'patientdeathdate',
        field: 'Date',
        computedRequired: (values) => {
          const reactions = values.reaction
          if (!reactions) return false
          return !!_.find(reactions, r => r.reactionoutcome === '5')
        },
        props: {
          shrink: true,
          name: 'patientdeathdate',
          label: 'Date of death',
          validate: [
            validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']),
            validators.isDateAfterOrEqual('reaction', 'Reaction start date', 'reactionstartdate'),
            validators.isDateAfterOrEqual('reaction', 'Reaction end date', 'reactionenddate'),
            validators.dateTimeNotInFuture('patientdeathdateformat', dateFormats),
            validators.isDateAfterOrEqual('drug', 'Drug start date', 'drugstartdate'),
            validators.isDateAfterOrEqual('drug', 'Drug end date', 'drugenddate')
          ],
          helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
          startYear: 1920
        }
      },
      {
        id: 'patientdeathcause',
        field: 'Repeatable',
        computedEditable: (values) => !!values.patientdeathdate,
        props: {
          shrink: true,
          label: 'Cause of death',
          name: 'patientdeathcause',
          hideIfEmpty: true,
          required: true,
          repeatableLabelPlaceholder: 'Cause of death',
          repeatableLabel: ['patientdeathreport'],
          schema: [
            {
              id: 'patientdeathreportmeddraversion',
              field: 'Input',
              computedValue: (values, parentValues, orgDetails) => orgDetails.meddraVersion,
              props: {
                type: 'hidden',
                shrink: true,
                name: 'patientdeathreportmeddraversion',
                label: 'Reported cause of death MedDRA Version',
                required: true
              }
            },
            {
              id: 'patientdeathreport',
              field: 'MedDRALookupInput',
              props: {
                required: false,
                shrink: true,
                name: 'patientdeathreport',
                label: 'Reported cause of death',
              }
            },
            //new R3 field
            {
              id: 'patientdeathreportfreetext',
              field: 'Input',
              computedRequired: values => {
                return !!values.patientdeathcause
              },
              props: {
                shrink: true,
                name: 'patientdeathreportfreetext',
                label: 'Patient death report free text',
                validate: [validators.maxLength(250, 'Field')],
                required: false
              }
            },
          ]
        }
      },

      {
        id: 'patientautopsyyesno',
        field: 'Dropdown',
        computedRequired: values => {
          return !!values.patientdeathdate
        },
        computedValue: (values) => !values.patientautopsyyesno && 'UNK',
        props: {
          shrink: true,
          name: 'patientautopsyyesno',
          label: 'Was autopsy done?',
          required: false,
          noNullOption: true,
          options: [
            { value: '1', label: 'Yes' },
            { value: '2', label: 'No' },
            { value: 'UNK', label: 'Unknown'}
          ]
        }
      },
      {
        id: 'patientautopsy',
        field: 'Repeatable',
        computedEditable: (values) => values.patientautopsyyesno === '1',
        props: {
          shrink: true,
          name: 'patientautopsy',
          hideIfEmpty: true,
          required: true,
          label: 'Patient Autopsy',
          repeatableLabelPlaceholder: 'Patient Autopsy',
          repeatableLabel: ['patientdetermineautopsy'],
          schema: [
            {
              id: 'patientdetermautopsmeddraversion',
              field: 'Input',
              computedValue: (values, parentValues, orgDetails) => orgDetails.meddraVersion,
              props: {
                type: 'hidden',
                shrink: true,
                name: 'patientdetermautopsmeddraversion',
                label: 'Autopsy-determined cause of death MedDRA Version',
                required: true
              }
            },
            {
              id: 'patientdetermineautopsy',
              field: 'MedDRALookupInput',
              props: {
                shrink: true,
                name: 'patientdetermineautopsy',
                label: 'Autopsy-determined cause of death',
                required: false,
              }
            },
            //new R3 field
            {
              id: 'patientdetermineautopsyfreetext',
              field: 'Input',
              computedRequired: values => {
                return !!values.patientdeathdate
              },
              props: {
                shrink: true,
                name: 'patientdetermineautopsyfreetext',
                label: 'patient determine autopsy free text',
                required: false,
                validate: [validators.maxLength(250, 'Field')]
              }
            }
          ]
        }
      }
    ]
  },
  parent: {
    title: 'Parent',
    layout: [
      'parentidentification:6',
      'parentage:6',
      'parentageunit:6',
      'parentweight:6',
      'parentheight:6',
      'parentsex:6',
      'parentlastmenstrualdate:6',
      'parentmedicalhistoryepisode:12',
      'parentmedicalrelevanttext:6'
    ],
    schema: [
      {
        id: 'parentidentification',
        field: 'Input',
        props: {
          shrink: true,
          name: 'parentidentification',
          label: 'Parent initials',
          validate: [validators.maxLength(60, 'Field')]
        }
      },
      {
        id: 'parentage',
        field: 'Input',
        props: {
          shrink: true,
          name: 'parentage',
          label: 'Parent age (years)',
          validate: [validators.maxNumericLength(2, 'Field')]
        }
      },
      {
        id: 'parentageunit',
        field: 'Input',
        computedRequired: values => {
          return !!values.parentage
        },
        computedValue: (values) => values.parentage ? 'year' : null,
        props: {
          shrink: true,
          name: 'parentageunit',
          label: 'Parent age unit',
          type: 'hidden'
        }
      },
      {
        id: 'parentweight',
        field: 'Input',
        props: {
          shrink: true,
          name: 'parentweight',
          label: 'Parent weight (kg)',
          validate: [validators.maxNumericLength(6, 'Field')]
        }
      },
      {
        id: 'parentheight',
        field: 'Input',
        props: {
          shrink: true,
          name: 'parentheight',
          label: 'Parent height (cm)',
          validate: [validators.maxNumericLength(3, 'Field')]
        }
      },
      {
        id: 'parentsex',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'parentsex',
          label: 'Parent sex',
          required: false,
          options: [
            { value: '1', label: 'Male' },
            { value: '2', label: 'Female' }
          ]
        }
      },
      {
        id: 'parentlastmenstrualdate',
        field: 'Date',
        computedEditable: (values) => values.parentsex === '2',
        props: {
          hideIfEmpty: true,
          shrink: true,
          name: 'parentlastmenstrualdate',
          label: 'Parent last menstrual period date',
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
          helperText: 'YYYY/MM/DD'
        }
      },
      {
        id: 'parentmedicalhistoryepisode',
        field: 'Repeatable',
        props: {
          shrink: true,
          name: 'parentmedicalhistoryepisode',
          label: 'Parent medical history episode',
          repeatableLabelPlaceholder: 'Medical history episode',
          repeatableLabel: ['parentmedicalepisodename'],
          required: false,
          schema: [
            {
              id: 'parentmdepisodemeddraversion',
              field: 'Input',
              computedValue: (values, parentValues, orgDetails) => orgDetails.meddraVersion,
              props: {
                type: 'hidden',
                shrink: true,
                name: 'parentmdepisodemeddraversion',
                label: 'Parent medical history MedDRA Version',
                required: true
              }
            },
            {
              id: 'parentmedicalepisodename',
              field: 'MedDRALookupInput',
              props: {
                shrink: true,
                name: 'parentmedicalepisodename',
                label: 'Parent medical history',
                required: false,
              }
            },
            {
              id: 'parentmedicalstartdate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'parentmedicalstartdate',
                label: 'Parent medical history start date',
                required: false,
                validate: [
                  validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']),
                  validators.isDateBeforeOrEqual('parentmedicalenddate', 'Parent medical history end date')
                ],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
              }
            },
            {
              id: 'parentmedicalcontinue',
              field: 'Dropdown',
              computedValue: (values) => !values.parentmedicalcontinue && 'UNK',
              props: {
                shrink: true,
                name: 'parentmedicalcontinue',
                label: 'Parent medical history continuing?',
                required: false,
                noNullOption: true,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: '2', label: 'No' },
                  { value: 'UNK', label: 'Unknown' },
                ]
              }
            },
            {
              id: 'parentmedicalenddate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'parentmedicalenddate',
                label: 'Parent medical history end date',
                required: false,
                validate: [
                  validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']),
                  validators.isDateAfterOrEqual('parentmedicalstartdate', 'Parent medical history start date')
                ],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
              }
            }
          ]
        }
      },
      {
        id: 'parentmedicalrelevanttext',
        field: 'Input',
        props: {
          shrink: true,
          name: 'parentmedicalrelevanttext',
          label: 'Parent medical history relevant text',
          validate: [validators.maxLength(10000, 'Field')]
        }
      }
    ]
  },
  reaction: {
    title: 'Reaction',
    layout: [
      'reaction:12'
    ],
    schema: [
      {
        id: 'reaction',
        field: 'Repeatable',
        props: {
          name: 'reaction',
          shrink: true,
          label: 'Reaction',
          repeatableLabel: ['primarysourcereaction', 'reactionmeddrallt'],
          required: true,
          schema: [
            {
              id: 'primarysourcereaction',
              field: 'Input',
              props: {
                shrink: true,
                name: 'primarysourcereaction',
                label: 'Reaction as reported by primary source',
                required: false,
                validate: [validators.maxLength(250, 'Field')]
              }
            },
            {
              id: 'primarysourcereactionlang',
              field: 'Input',
              computedValue: () => window.navigator.userLanguage || window.navigator.language,
              props: {
                type: 'hidden',
                shrink: true,
                name: 'primarysourcereactionlang',
                label: 'Primary source reaction language',
                required: true
              }
            },
            {
              id: 'reactionmeddraversionllt',
              field: 'Input',
              computedValue: (values, parentValues, orgDetails) => orgDetails.meddraVersion,
              props: {
                type: 'hidden',
                shrink: true,
                name: 'reactionmeddraversionllt',
                label: 'Reaction (MedDRA LLT) MedDRA Version',
                required: true
              }
            },
            {
              id: 'reactionmeddrallt',
              field: 'MedDRALookupInput',
              props: {
                shrink: true,
                name: 'reactionmeddrallt',
                label: 'Reaction (MedDRA LLT)',
                required: true,
              }
            },
            {
              id: 'reactionstartdate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'reactionstartdate',
                label: 'Reaction start date',
                required: false,
                validate: [
                  validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']),
                  validators.isDateBeforeOrEqual('reactionenddate', 'Reaction end date'),
                  validators.isDateBeforeOrEqual('_parentFormValues.transmissiondate', 'Date of this transmission'),
                  validators.isDateBeforeOrEqual('_parentFormValues.receivedate', 'Date report was first received from source'),
                  validators.isDateBeforeOrEqual('_parentFormValues.receiptdate', 'Date of receipt of the most recent information for this report'),
                  validators.isDateBeforeOrEqual('_parentFormValues.patientdeathdate', 'Date of death'),
                  validators.isDateAfterOrEqual('_parentFormValues.drug', 'Drug Start Date', 'drugstartdate'),
                  validators.dateTimeNotInFuture('reactionstartdateformat', dateFormats)
                ],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
                startYear: 1920
              }
            },
            {
              id: 'reactionenddate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'reactionenddate',
                label: 'Reaction end date',
                required: false,
                validate: [
                  validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']),
                  validators.isDateAfterOrEqual('reactionstartdate', 'Reaction start date'),
                  validators.isDateBeforeOrEqual('_parentFormValues.patientdeathdate', 'Date of death'),
                  validators.dateTimeNotInFuture('reactionenddateformat', dateFormats)
                ],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
                startYear: 1920
              }
            },
            //new R3 fields
             {
              id: 'reactionseriousnessdeath',
              field: 'Dropdown',
              computedValue: (values) => !values.reactionseriousnessdeath && 'NI',
              props: {
                shrink: true,
                name: 'reactionseriousnessdeath',
                label: 'Reaction results in death?',
                required: true,
                noNullOption: true,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: 'NI', label: 'No Information'}
                ]
              }
            },
            {
              id: 'reactionseriousnesslifethreatening',
              field: 'Dropdown',
              computedValue: (values) => !values.reactionseriousnesslifethreatening && 'NI',
              props: {
                shrink: true,
                name: 'reactionseriousnesslifethreatening',
                label: 'Reaction is life threatening?',
                required: true,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: 'NI', label: 'No Information'}
                ]
              }
            },
            {
              id: 'reactionseriousnesshospitalization',
              field: 'Dropdown',
              computedValue: (values) => !values.reactionseriousnesshospitalization && 'NI',
              props: {
                shrink: true,
                name: 'reactionseriousnesshospitalization',
                label: 'Reaction caused prolonged hospitalization?',
                required: true,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: 'NI', label: 'No Information'}
                ]
              }
            },
            {
              id: 'reactionseriousnessdisabling',
              field: 'Dropdown',
              computedValue: (values) => !values.reactionseriousnessdisabling && 'NI',
              props: {
                shrink: true,
                name: 'reactionseriousnessdisabling',
                label: 'Reaction results in disabling/incapacitating?',
                required: true,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: 'NI', label: 'No Information'}
                ]
              }
            },
            {
              id: 'reactionseriousnesscongenitalanomali',
              field: 'Dropdown',
              computedValue: (values) => !values.reactionseriousnesscongenitalanomali && 'NI',
              props: {
                shrink: true,
                name: 'reactionseriousnesscongenitalanomali',
                label: 'Reaction results in congenital anomali/birth defect?',
                required: true,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: 'NI', label: 'No Information'}
                ]
              }
            },
            {
              id: 'reactionseriousnessother',
              field: 'Dropdown',
              computedValue: (values) => !values.reactionseriousnessother && 'NI',
              props: {
                shrink: true,
                name: 'reactionseriousnessother',
                label: 'Reaction results in other medically important?',
                required: true,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: 'NI', label: 'No Information'}
                ]
              }
            },
            {
              id: 'reactionoccurcountry',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'reactionoccurcountry',
                label: 'Country where the reaction/event occured?',
                required: false,
                options: countryCodes
              }
            },
            {
              id: 'reactionoutcome',
              field: 'Dropdown',
              computedValue: (values, parentFormValues) => parentFormValues.patientdeathdate ? '5' : null,
              props: {
                shrink: true,
                name: 'reactionoutcome',
                label: 'Reaction outcome',
                required: true,
                options: [
                  { value: '1', label: 'Recovered/resolved' },
                  { value: '2', label: 'Recovering/resolving' },
                  { value: '3', label: 'Not recovered/not resolved' },
                  { value: '4', label: 'Recovered/resolved with sequelae' },
                  { value: '5', label: 'Fatal' },
                  { value: '0', label: 'Unknown' }
                ]
              }
            }
          ]
        }
      }
    ]
  },
  medicine: {
    title: 'Drug',
    layout: [
      'drug:12'
    ],
    schema: [
      {
        id: 'drug',
        field: 'Repeatable',
        props: {
          shrink: true,
          name: 'drug',
          label: 'Drugs',
          formId: 'drugrepeatable',
          repeatableLabelPlaceholder: 'Drug',
          repeatableLabel: ['medicinalproduct', 'drugcharacterization'],
          required: true,
          schema: [
            {
              id: 'drugcharacterization',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'drugcharacterization',
                label: 'Drug characterisation',
                required: true,
                options: [
                  { value: '1', label: 'Suspect' },
                  { value: '2', label: 'Concomitant' },
                  { value: '3', label: 'Interacting' },
                  { value: '4', label: 'Drug Not Administered' }
                ]
              }
            },
            {
              id: 'medicinalproduct',
              field: 'DrugLookupInput',
              props: {
                shrink: true,
                name: 'medicinalproduct',
                label: 'Medicinal product',
                required: true,
                validate: [validators.maxLength(250, 'Field')]
              }
            },
            {
              id: 'activesubstancename',
              field: 'Input',
              props: {
                shrink: true,
                name: 'activesubstancename',
                label: 'Active substance',
                required: false,
                validate: [validators.maxLength(250, 'Field')]
              }
            },
            {
              id: 'obtaindrugcountry',
              field: 'Dropdown',
              props: {
                shrink: true,
                autocomplete: true,
                options: countryCodes,
                name: 'obtaindrugcountry',
                label: 'Country where drug was obtained',
                required: false
              }
            },
            {
              id: 'drugbatchnumb',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugbatchnumb',
                label: 'Batch/Lot number',
                required: false,
                validate: [validators.maxLength(35, 'Field')]
              }
            },
            {
              id: 'drugauthorizationholder',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugauthorizationholder',
                label: 'Drug authorisation holder',
                required: false,
                validate: [validators.maxLength(60, 'Field')]
              }
            },
            {
              id: 'drugstructuredosagenumb',
              field: 'Input',
              computedRequired: ({ drugcharacterization, drugstructuredosageunit }) => !!drugcharacterization && !!drugstructuredosageunit,
              props: {
                shrink: true,
                name: 'drugstructuredosagenumb',
                label: 'Dose (number)',
                required: false,
                validate: [validators.maxNumericLength(8, 'Field')]
              }
            },
            {
              id: 'drugstructuredosageunit',
              field: 'Dropdown',
              computedRequired: ({ drugstructuredosagenumb }) => !!drugstructuredosagenumb,
              props: {
                shrink: true,
                name: 'drugstructuredosageunit',
                label: 'Dose (unit)',
                required: false,
                options: drugDosageUnits
              }
            },
            {
              id: 'drugintervaldosageunitnumb',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugintervaldosageunitnumb',
                label: 'Number of units in interval',
                required: false,
                validate: [validators.maxNumericLength(4, 'Field')]
              }
            },
            {
              id: 'drugintervaldosagedefinition',
              field: 'Dropdown',
              computedRequired: ({ drugintervaldosageunitnumb }) => !!drugintervaldosageunitnumb,
              props: {
                shrink: true,
                name: 'drugintervaldosagedefinition',
                label: 'Dosage time interval unit',
                required: false,
                options: [
                  { value: '{cyclical}', label: '{cyclical}' },
                  { value: '{asnecessary}', label: '{asnecessary}' },
                  { value: '{total}', label: '{total}' },
                  { value: 'a', label: 'Year' },
                  { value: 'a', label: 'Year' },
                  { value: 'mo', label: 'Month' },
                  { value: 'wk', label: 'Week' },
                  { value: 'd', label: 'Day' },
                  { value: 'h', label: 'Hour' },
                  { value: 'm', label: 'Minute' }
                ]
              }
            },
            {
              id: 'drugcumulativedosagenumb',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugcumulativedosagenumb',
                label: 'Cumulative dose to first reaction',
                required: false,
                validate: [validators.maxNumericLength(10, 'Field')]
              }
            },
            {
              id: 'drugcumulativedosageunit',
              field: 'Dropdown',
              computedRequired: ({ drugcumulativedosagenumb }) => !!drugcumulativedosagenumb,
              props: {
                shrink: true,
                name: 'drugcumulativedosageunit',
                label: 'Cumulative dose to first reaction (unit)',
                required: false,
                options: drugDosageUnits
              }
            },
            {
              id: 'drugdosagetext',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugdosagetext',
                label: 'Dosage text',
                required: false,
                validate: [validators.maxLength(2000, 'Field')]
              }
            },
            {
              id: 'drugdosageform',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugdosageform',
                label: 'Pharmaceutical form',
                restrictFreeformEntry: true,
                required: false,
                validate: [validators.maxLength(60, 'Field')]
              }
            },
            {
              id: 'drugadministrationroute',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'drugadministrationroute',
                label: 'Route of administration',
                required: false,
                restrictFreeformEntry: true,
                options: drugAdministrationRoutesR3
              }
            },
            {
              id: 'drugparadministration',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'drugparadministration',
                label: 'Parent route of administration',
                required: false,
                restrictFreeformEntry: true,
                options: drugAdministrationRoutesR3
              }
            },
            {
              id: 'reactiongestationperiod',
              field: 'Input',
              computedHidden: (values, parentValues) => parentValues.patientsex !== '2',
              props: {
                hideIfEmpty: true,
                shrink: true,
                name: 'reactiongestationperiod',
                label: 'Gestation period at time of exposure',
                required: false,
                validate: [validators.maxNumericLength(3, 'Field')]
              }
            },
            {
              id: 'reactiongestationperiodunit',
              field: 'Dropdown',
              computedHidden: (values, parentValues) => parentValues.patientsex !== '2',
              computedRequired: ({ reactiongestationperiod }) => !!reactiongestationperiod,
              props: {
                shrink: true,
                name: 'reactiongestationperiodunit',
                label: 'Gestation period at time of exposure (unit)',
                required: false,
                options: [
                  { value: 'mo', label: 'Month' },
                  { value: 'wk', label: 'Week' },
                  { value: 'd', label: 'Day' },
                  { value: '{trimester}', label: '{Trimester}' }
                ]
              }
            },
            {
              id: 'drugindicationmeddraversion',
              field: 'Input',
              computedValue: (values, parentValues, orgDetails) => orgDetails.meddraVersion,
              props: {
                type: 'hidden',
                shrink: true,
                name: 'drugindicationmeddraversion',
                label: 'Drug indication MedDRA Version',
                required: true
              }
            },
            {
              id: 'drugindication',
              field: 'MedDRALookupInput',
              computedRequired: ({ drugindicationmeddraversion }) => !!drugindicationmeddraversion,
              props: {
                shrink: true,
                name: 'drugindication',
                label: 'Drug indication',
                required: false,
              }
            },
            {
              id: 'drugstartdate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'drugstartdate',
                label: 'Drug start date',
                required: false,
                validate: [
                  validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']),
                  validators.isDateBeforeOrEqual('drugenddate', 'Drug end date'),
                  validators.isDateBeforeOrEqual('_parentFormValues.reaction', 'Reaction Start Date', 'reactionstartdate'),
                  validators.dateTimeNotInFuture('drugstartdateformat', dateFormats),
                  validators.isDateBeforeOrEqual('_parentFormValues.patientdeathdate', 'Date of death')
                ],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
                startYear: 1920
              }
            },
            {
              id: 'drugenddate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'drugenddate',
                label: 'Drug end date',
                required: false,
                validate: [
                  validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']),
                  validators.isDateAfterOrEqual('drugstartdate', 'Drug start date'),
                  validators.dateTimeNotInFuture('drugenddateformat', dateFormats),
                  validators.isDateBeforeOrEqual('_parentFormValues.patientdeathdate', 'Date of death')
                ],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
                startYear: 1920
              }
            },
            {
              id: 'actiondrug',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'actiondrug',
                label: 'Action taken with drug',
                required: false,
                options: [
                  { value: '1', label: 'Drug withdrawn' },
                  { value: '2', label: 'Dose reduced' },
                  { value: '3', label: 'Dose increased' },
                  { value: '4', label: 'Dose not changed' },
                  { value: '0', label: 'Unknown' },
                  { value: '9', label: 'Not applicable' }
                ]
              }
            },
            {
              id: 'drugrecurreadministration',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'drugrecurreadministration',
                label: 'Did reaction recur on readministration?',
                required: false,
                options: [
                  { value: '1', label: 'yes – yes (rechallenge was done, reaction recurred)' },
                  { value: '2', label: 'yes – no (rechallenge was done, reaction did not recur)' },
                  { value: '3', label: 'yes – unk (rechallenge was done, outcome unknown)' },
                  { value: '3', label: 'no – n/a (no rechallenge was done, recurrence is not applicable)' }
                ]
              }
            }
          ]
        }
      }
    ]
  },
  causality: {
    title: 'Causality Assessment',
    layout: [
      'causalityAssessment:12'
    ],
    schema: [
      { // supress causality field not existing warning
        id: 'causality',
        field: 'input',
        props: {
          type: 'hidden'
        }
      },
      {
        id: 'causalityAssessment',
        field: 'CombinedRepeatable',
        props: {
          name: 'causalityAssessment',
          label: 'Causality',
          layout: [
            'causality:12'
          ],
          sourceField: {
            id: 'drug',
            groupKey: 'id',
            labelKey: 'medicinalproduct',
            labelExtra: [],
            filterValues: {
              drugcharacterization: ['1', '3']
            }
          },
          targetField: {
            id: 'reaction',
            labelKey: 'reactionmeddrallt'
          },
          repeatableGroupId: 'causality',
          requiredFieldValue: 'causalityAssessmentRequired',
          prepopulate: true,
          emptyTranslationKey: 'Causality Empty',
          schema: [
            {
              id: 'causality',
              field: 'Repeatable',
              props: {
                hideIfEmpty: false,
                formId: 'causalityForm',
                name: 'causalityForm',
                label: 'Causality test',
                repeatableLabelPlaceholder: 'Reaction',
                repeatableLabel: ['targetName', 'drugassessmentsource', 'drugassessmentmethod', 'drugresult'],
                shrink: true,
                modalIndex: 1,
                schema: [
                  {
                    id: 'drugassessmentsource',
                    field: 'Input',
                    props: {
                      shrink: true,
                      name: 'drugassessmentsource',
                      label: 'Source of assessment',
                      required: true,
                      validate: [validators.maxLength(60, 'Field')]
                    }
                  },
                  {
                    id: 'drugassessmentmethod',
                    field: 'Input',
                    props: {
                      shrink: true,
                      name: 'drugassessmentmethod',
                      label: 'Method of assessment',
                      required: true,
                      validate: [validators.maxLength(60, 'Field')]
                    }
                  },
                  {
                    id: 'drugresult',
                    field: 'Input',
                    props: {
                      shrink: true,
                      name: 'drugresult',
                      label: 'Result of assessment',
                      required: true,
                      validate: [validators.maxLength(60, 'Field')]
                    }
                  }
                ]
              }
            }
          ]
        }
      }
    ]
  },
  test: {
    title: 'Test',
    layout: [
      'test:12'
    ],
    schema: [
      {
        id: 'test',
        field: 'Repeatable',
        props: {
          shrink: true,
          name: 'test',
          label: 'Test',
          repeatableLabel: ['testdate', 'testname'],
          schema: [
            {
              id: 'testdate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'testdate',
                label: 'Test date',
                validate: [
                  validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']),
                  validators.dateTimeNotInFuture('testdateformat', dateFormats)
                ],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
                startYear: 1920
              }
            },
            {
              id: 'testname',
              field: 'MedDRALookupInput',
              computedRequired: ({ testdate }) => !!testdate,
              props: {
                shrink: true,
                name: 'testname',
                label: 'Test name',
              }
            },
            {
              id: 'testresult',
              field: 'Input',
              props: {
                shrink: true,
                name: 'testresult',
                label: 'Test Result',
                validate: [validators.maxLength(50, 'Field')]
              }
            },
            {
              id: 'testunit',
              field: 'Dropdown',
              computedRequired: ({ testresult }) => !!testresult,
              props: {
                shrink: true,
                name: 'testunit',
                label: 'Test result (unit)',
                options: drugDosageUnits
              }
            },
            //new R3 field
            {
              id: 'testcomments',
              field: 'Input',
              props: {
                shrink: true,
                name: 'testcomments',
                label: 'Test Comments',
                validate: [validators.maxLength(2000, 'Field')]
              }
            }
          ]
        }
      }
    ]
  },
  narrative: {
    title: 'Narrative',
    layout: [
      'narrativeincludeclinical:12'
    ],
    schema: [
      {
        id: 'narrativeincludeclinical',
        field: 'Input',
        props: {
          shrink: true,
          name: 'narrativeincludeclinical',
          label: 'Case narrative',
          validate: [validators.maxLength(100000, 'Field')],
          multiline: true
        }
      },
      {
        id: 'reportercomment',
        field: 'Input',
        props: {
          shrink: true,
          name: 'reportercomment',
          label: `Reporter's comments`,
          validate: [validators.maxLength(20000, 'Field')]
        }
      },
      {
        id: 'sendercomment',
        field: 'Input',
        props: {
          shrink: true,
          name: 'sendercomment',
          label: `Sender's comments`,
          validate: [validators.maxLength(20000, 'Field')]
        }
      }
    ]
  }
}

export default {
  initialFieldMap,
  formSchema
}
