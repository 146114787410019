import React, { useCallback, useRef } from 'react'

import _ from 'lodash'
import moment from 'moment'

import { dateFormat } from '../../../config'

import DatePicker from './DatePicker'

const formatDate = (date, format) => {
  const formattedValue = moment(new Date(date)).format(format)

  if (!moment(formattedValue, format, true).isValid()) {
    return
  }

  return formattedValue
}

const DatePickerContainer = (props) => {
  const { current: id } = useRef(_.uniqueId('dateInput_'))
  const { current: defaultValue } = useRef(formatDate(props.value, 'YYYY-MM-DD'))

  const onChange = useCallback(_.debounce((e) => {
    const internalDate = formatDate(e.target.value, dateFormat)

    if (!internalDate) {
      return
    }

    props.onChange(internalDate)
  }, 500), [])

  return (
    <DatePicker
      {...props}
      id={id}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  )
}

export default DatePickerContainer
