import React, { useMemo, useState, useCallback, useEffect, memo, useRef } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import _ from 'lodash'

import { translations } from '../../../../../config'
import { withStyles } from '@material-ui/core/styles'

import Scopes from './scopes'
import ColumnMenu from './ColumnMenu'
import Toolbar from './Toolbar'
import modalService from '../../../../../services/modalService'
import SaveChangesModal from '../SaveTranslationChangesModal'
import DeleteLanguageModal from '../DeleteLanguageModal'
import CreateKeyModal from '../CreateKeyModal'
import Types from './types'
import DeleteTranslationModal from '../DeleteTranslationModal'
import GridCellExpand from './GridCellExpand'
import styles from './style'
import TooltipIcon from '../../../../../components/TooltipIcon'
import LockIcon from '@material-ui/icons/Lock'

const KEY_COLUMN_NAME = 'key'
const IS_SYSTEM_KEY_COLUMN_NAME = 'isSystemKey'
const DEFAULT_TRANSLATION_ISO_CODE = 'en'

const Table = memo(({
  organisationLanguages,
  searchedTranslations,
  onTablePageChange,
  totalTranslationCount,
  rowsPerPage,
  onCreateKey,
  languages,
  onSubmitTranslationChanges,
  onSubmitTranslationDelete,
  onDeleteLanguage,
  setCanSave,
  canSave,
  classes,
  showSource,
  showAddLanguageModal,
  isAfterSave
}) => {
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState([])
  const [modifiedCells, setModifiedCells] = useState({})
  const [selectionModel, setSelectionModel] = useState([])
  const [canDelete, setCanDelete] = useState(false)
  const [canUpdate, setCanUpdate] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const initialRows = useMemo(() => {
    return searchedTranslations.map(({ keyId, key, keyScope, translations, tags, updateType }) => {
      const translationCells = translations.reduce((prev, curr) => {
        const { id, value, languageIsoCode, updateType } = curr

        const { name: languageName } = languages.find(({ isoCode }) => isoCode === languageIsoCode)

        return {
          ...prev,
          [languageIsoCode]: { value, id, initial: value, key, keyId, languageIsoCode, type: Types.TRANSLATION, languageName, updateType }
        }
      }, {})

      return { 
        id: keyId, 
        [IS_SYSTEM_KEY_COLUMN_NAME]: keyScope === Scopes.GLOBAL,
        [KEY_COLUMN_NAME]: { 
          value: key, 
          initial: key, 
          scope: keyScope, 
          id: keyId, 
          type: Types.KEY, 
          tags, 
          updateType 
        }, 
        ...translationCells 
      }
    })
  }, [searchedTranslations, languages])

  const deletedRows = useMemo(() => {
    return initialRows.filter((row) => { return selectionModel.includes(row.id) })
  }, [initialRows, selectionModel])

  function renderCellExpand(params) {
    const { id, field, colDef, value, api, row } = params
    const isKeyField = field === KEY_COLUMN_NAME
    const tags = row[KEY_COLUMN_NAME]?.tags
    const updateType = row[field]?.updateType
    return (
      <GridCellExpand
        id={id}
        field={field}
        colDef={colDef}
        value={value}
        api={api}
        canUpdate={canUpdate}
        tags={tags}
        showKeyTag={isKeyField && !_.isEmpty(tags)}
        isCellGlobal={isKeyField && row.key.scope === Scopes.GLOBAL}
        updateType={updateType}
        showSource={showSource}
      />
    )
  }

  function renderPadLockCell(params) {
    if (!params.value) {
      return ''
    }
    return (
      <TooltipIcon title={translations('Manage Translations - System key tooltip message')} icon={<LockIcon />} />
    )
  }

  const columns = useMemo(() => {
    const languageHeaders = organisationLanguages.map(({ label, isoCode, value }) => ({
      field: isoCode,
      headerName: label,
      width: 220,
      editable: true,
      sortable: false,
      languageId: value,
      disableColumnMenu: isoCode === DEFAULT_TRANSLATION_ISO_CODE,
      valueGetter: (params) => params.row[isoCode]?.value,
      renderCell: renderCellExpand
    })).sort((a, b) => a.field === DEFAULT_TRANSLATION_ISO_CODE ? -1 : b.field === DEFAULT_TRANSLATION_ISO_CODE ? 1 : 0)

    return [
      {
        field: IS_SYSTEM_KEY_COLUMN_NAME,
        headerName: ' ',
        width: 53,
        editable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: renderPadLockCell
      },
      {
        field: KEY_COLUMN_NAME,
        headerName: translations('Manage Translations - Table Key Column Header'),
        width: 320,
        editable: true,
        sortable: false,
        disableColumnMenu: true,
        valueGetter: (params) => params.row[KEY_COLUMN_NAME]?.value,
        renderCell: renderCellExpand
      },
      ...languageHeaders
    ]
  }, [organisationLanguages, renderCellExpand])

  const showDeletionModal = useCallback(() => {
    modalService.open({
      component: DeleteTranslationModal,
      largeModal: true,
      changes: deletedRows,
      onSubmitTranslationDelete: (changes) => {
        onSubmitTranslationDelete(changes).then(() => {
          setModifiedCells({})
        })
        modalService.close()
      },
      onClose: () => modalService.close()
    })
  }, [deletedRows, onSubmitTranslationDelete])

  const showChangesModal = useCallback(() => {
    modalService.open({
      component: SaveChangesModal,
      largeModal: true,
      changes: Object.values(modifiedCells),
      onSubmitTranslationChanges: (changes) => {        
        setIsSaving(true)
        onSubmitTranslationChanges(changes).then(() => {
          setModifiedCells({})
          setCanUpdate(false)
        })
        modalService.close()
      },
      onClose: () => modalService.close()
    })
  }, [modifiedCells, onSubmitTranslationChanges])

  const showDeleteModal = useCallback(({ id, languageName }) => {
    modalService.open({
      component: DeleteLanguageModal,
      languageName,
      languageId: id,
      onDelete: (languageId) => {
        onDeleteLanguage(languageId)
        modalService.close()
      },
      onCancel: () => modalService.close()
    })
  }, [onDeleteLanguage])

  const showCreateKeyModal = useCallback(() => {
    modalService.open({
      component: CreateKeyModal,
      onCreate: (key) => {
        onCreateKey(key)
        modalService.close()
      }
    })
  }, [onCreateKey])

  const resetTableState = useCallback(() => {
    setModifiedCells({})
    setRows(initialRows)
  }, [initialRows])

  useEffect(() => {
    if (isAfterSave) {
      setRows(initialRows)
      setIsSaving(false)
    } else if (!canUpdate && !isSaving) {
      resetTableState()
    }
  }, [searchedTranslations, resetTableState, isAfterSave, canUpdate, initialRows, isSaving])

  const isFirstMount = useRef(true)
  
  useEffect(() => {
    if (isFirstMount.current) {
      setRows(initialRows)
      isFirstMount.current = false
    }
  }, [initialRows])

  useEffect(() => {
    if (!isFirstMount.current && Object.keys(modifiedCells).length > 0) {
      setRows(currentRows => {
        return currentRows.map(row => {
          const rowUpdates = {}
          
          Object.entries(modifiedCells).forEach(([cellId, cellData]) => {
            const [keyId, fieldName] = cellId.split('.')
            if (row.id === keyId && fieldName) {
              rowUpdates[fieldName] = cellData
            }
          })
          
          return Object.keys(rowUpdates).length > 0 
            ? { ...row, ...rowUpdates } 
            : row
        })
      })
    }
  }, [modifiedCells])

  useEffect(() => {
    setCanSave(!_.isEmpty(modifiedCells) && !isEditing)
  }, [setCanSave, modifiedCells, isEditing])

  useEffect(() => {
    setCanDelete(!_.isEmpty(deletedRows))
  }, [setCanDelete, deletedRows])

  const isCellEditable = useCallback((params) => {
    if (!canUpdate) {
      return false
    }
    switch (params.field) {
      case KEY_COLUMN_NAME: {
        return !(params.row.key.scope === Scopes.GLOBAL)
      }
      default:
        return true
    }
  }, [canUpdate])

  const isRowSelectable = useCallback((params) => {
    if (canUpdate) {
      return false
    }
    return _.get(params, 'row.key.scope') === Scopes.ORGANISATION
  }, [canUpdate])

  const onCellEditCommit = useCallback(({ id, field, value = '', colDef }) => {
    setIsEditing(false)
    let updatedCell = {}
    setRows(currentRows => {
      const updatedRows = currentRows.map((row) => {
        if (row.id === id) {
          if (field === KEY_COLUMN_NAME) {
            const newKey = value
            const isNewKeyValid = newKey.trim().length > 0
            if (!isNewKeyValid) {
              updatedCell = row[KEY_COLUMN_NAME]
              return row
            }
          }
          if (!row[field]) {
            updatedCell = {
              type: Types.NEW_TRANSLATION,
              keyId: id,
              key: row[KEY_COLUMN_NAME].value,
              languageIsoCode: field,
              languageName: colDef.headerName,
              languageId: colDef.languageId,
              value
            }
          } else {
            updatedCell = { ...row[field], value }
          }
          return {
            ...row,
            [field]: updatedCell
          }
        }
        return row
      })
      
      return updatedRows
    })

    const targetRow = initialRows.find((row) => row.id === id)
    if (!targetRow) {
      console.warn(`Could not find row with id ${id} in initialRows`)
      
      const cellId = `${id}.${field}`
      setModifiedCells(prev => ({
        ...prev,
        [cellId]: updatedCell
      }))
      
      return
    }

    const targetCell = targetRow[field]
    const cellId = `${targetRow[KEY_COLUMN_NAME]?.id || id}.${field}`

    setModifiedCells(prev => ({
      ...prev,
      [cellId]: updatedCell
    }))

    const isExistingCell = !!targetCell
    if (isExistingCell) {
      const hasChanged = !_.isEqual(targetCell, updatedCell)
      if (!hasChanged) {
        setModifiedCells((prevModifiedCells) => {
          const newState = { ...prevModifiedCells }
          delete newState[cellId]
          return newState
        })
      }
    } else {
      if (!updatedCell.value) {
        setModifiedCells((prevModifiedCells) => {
          const newState = { ...prevModifiedCells }
          delete newState[cellId]
          return newState
        })
      }
    }
  }, [initialRows])

  useEffect(() => {
    setModifiedCells({})
  }, [canUpdate])

  const onPageChange = useCallback((pageNumber) => {
    setPage(pageNumber)
    onTablePageChange(pageNumber)
  }, [])

  return (
    <DataGrid className={canUpdate ? classes.root : null}
      checkboxSelection
      page={page}
      onSelectionModelChange={setSelectionModel}
      selectionModel={selectionModel}
      onPageChange={onPageChange}
      rowCount={totalTranslationCount}
      onCellEditCommit={onCellEditCommit}
      onCellEditStart={() => setIsEditing(true)}
      isCellEditable={isCellEditable}
      isRowSelectable={isRowSelectable}
      rows={rows}
      columns={columns}
      pageSize={rowsPerPage}
      rowsPerPageOptions={[rowsPerPage]}
      paginationMode="server"
      components={{ ColumnMenu, Toolbar }}
      componentsProps={{
        columnMenu: { onDeleteLanguage: showDeleteModal },
        toolbar: {
          canSave,
          onSaveChanges: showChangesModal,
          onCreateKey: showCreateKeyModal,
          onAddLanguage: showAddLanguageModal,
          canDelete,
          onDelete: showDeletionModal,
          canUpdate,
          setCanUpdate,
          resetTableState
        }
      }}
      autoHeight
    />
  )
})

export default withStyles(styles)(Table)
