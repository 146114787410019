import React, { useState } from 'react'
import _ from 'lodash'

import { styled } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import Tooltip from '@material-ui/core/Tooltip'
import { translations } from '../../../../../config'
import Heading from '../../../../../components/Heading'
import Checkbox from '@material-ui/core/Checkbox';
import { getIcon, hasSelectedFields, hasUnSelectedFields } from '../helpers'

import IconButton from '@material-ui/core/IconButton'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'

const Container = styled('div')(({ theme, fieldContainer = false }) => ({
  marginLeft: 20,
  marginRight: 20,
  marginBottom: 20,
  border: !fieldContainer && `1px solid ${theme.palette.grey[300]}`,
}))


const Header = styled('header')(({ theme, collapsible, disabled }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(2),
  cursor: collapsible ? 'pointer' : 'default',
  '& .MuiFormControlLabel-root': {
    marginRight: 0
  }
}))


const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  paddingRight: theme.spacing(0)
}))

const Field = styled('div')(({ theme, isSubField }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  paddingRight: theme.spacing(2),
  marginBottom: theme.spacing(1),
  marginLeft: isSubField ? theme.spacing(5) : '0',
}))


const Icon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 34,
  height: 34,
  color: theme.palette.grey[800],
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: 32,
  background: '#fff',
}))


const Label = styled('p')(({ theme }) => ({
  ...theme.typography.body2,
  lineHeight: 1.4,
  margin: 0
}))


const Meta = styled(Label)(({ theme, isHeader }) => ({
  fontSize: isHeader ? 13 : 11,
  marginRight: theme.spacing(1)
}))

const Spacer = styled('span')(({ theme }) => ({
  width: 42,
  height: 42,
  display: 'block',
}))


const ReportSections = ({section, onSelectSection, onSelectField}) => {
  const { displayName, name, id: sectionId, fields } = section
  
  const [isOpen, setIsOpen] = useState(false)
  const onOpenToggle = () => {
    setIsOpen(!isOpen)
  }

  const renderField = ({field, parentFieldId}) => {
    const { isHeading, name, displayName, fields = [], isCustom, id: fieldId, isSelected } = field
  
    const hasSelected = hasSelectedFields(fields)
    const hasUnSelected = hasUnSelectedFields(fields)

    const isRepeatable = !isHeading && _.size(fields)
    const fieldType = isHeading ? 'HEADING' : isRepeatable ? 'REPEATABLE' : 'QUESTION'
    if (isCustom) {
      return 
    }
  
    const handleCheckboxClick = ({e, fieldId}) => {
      const isChecked = e.target.checked
      onSelectField({isChecked, fieldId})
    }
  
    return (
      <Field isSubField={parentFieldId} key={fieldId}>
        <Grid container spacing={1} alignItems='center' wrap='nowrap'>
          <Grid item>
            {isHeading ? 
              <Spacer /> :
              <Checkbox
                checked={isRepeatable ? !hasUnSelected : isSelected}
                indeterminate={hasUnSelected && hasSelected}
                onChange={(e) => handleCheckboxClick({e, fieldId})}
              />
            }
            
          </Grid>
          
          <Grid item>
            <Icon>
              <Tooltip title={translations(`Report Configuration - Question Type - ${fieldType}`)} placement='top'>
                {getIcon({ type: fieldType })}
              </Tooltip>
            </Icon>
          </Grid>
      
          <Grid item>
            <Label>{displayName || name || fieldId}</Label>
            {isHeading && displayName && <Meta>{name}</Meta>}
            {!isHeading && <Meta>{fieldId}</Meta>}
          </Grid>
          
        </Grid>
      </Field>
    )
  }

  const hasSelected = hasSelectedFields(fields)
  const hasUnSelected = hasUnSelectedFields(fields)

  return (
    <Container key={sectionId}>
      <Header>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <Checkbox
              checked={!hasUnSelected}
              indeterminate={hasUnSelected && hasSelected}
              onChange={(e) => { onSelectSection(e, sectionId) }}
            />
          </Grid>

          <Grid item onClick={onOpenToggle}>
            <IconButton size='small' aria-label={translations(isOpen ? 'Close' : 'Open')}>
              {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
          <Grid item xs onClick={onOpenToggle}>
            <Heading component='h3' variant='h5'>
              {displayName || name}
              {displayName && <Meta isHeader={true}>{name}</Meta>}
            </Heading>
          </Grid>
        </Grid>
      </Header>
      <Collapse in={isOpen} timeout='auto' unmountOnExit>
        <Content>
          <Container fieldContainer={true}>
            {_.map(fields, (field) => {
              const { fields = [], id: parentFieldId } = field
              return (
                <div key={parentFieldId}>
                  {renderField({field})}
                  {_.map(fields, (subField) => renderField({field: subField, parentFieldId}))}
                </div>
              )}
            )}
          </Container>
              
        </Content>
      </Collapse>
    </Container>
  )
  
}

export default ReportSections
