import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useWebsites } from '../contexts'
import Drawer from '../../../components/Drawer'
import { CLOSE } from '../contexts/constants'
import Form, { FormBody, FormSubmit } from '../../../components/Form'
import { useDispatch, useSelector } from 'react-redux'
import { submit, getFormValues } from 'redux-form'
import { translations } from '../../../config'
import toastService from '../../../services/toastService'
import { PAGE_FORM_NAME } from '../../../store/modules/websiteManagement/constants'
import { styled } from '@material-ui/core'
import * as validators from '../../../validators'
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core'
import { selectors as translationSelectors } from '../../../store/modules/translations'
import { actions as translationActions } from '../../../store/modules/translations'
import { selectors as authSelectors } from '../../../store/modules/auth'

const toastError = ({ error }) => toastService.action({
  type: 'error',
  message: `${translations(`Failure - Website Management Page Update`)} - ${error}`
})

const toastSuccess = ({ }) => toastService.action({
  type: 'success',
  message: translations(`Success - Website Management Page Update`)
})

const SubmitContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1),
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
}))

const LanguageSelector = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  minWidth: 120
}))

const formName = PAGE_FORM_NAME
const PageForm = Form(formName)

const PageOverlay = (props) => {
  const {
    state,
    toggleOverlay,
    updatePage
  } = useWebsites()
  const dispatch = useDispatch()

  const organisationId = useSelector(authSelectors.getUserSelectedOrganisationId)
  const organisationLanguages = useSelector(translationSelectors.getCurrentOrganisationLanguagesAsOptions)
 
  useEffect(() => {
    if (organisationId) {
      dispatch(translationActions.findOrganisationLanguages({ organisationId }))
    }
  }, [organisationId, dispatch])

  const languageObject = Object.fromEntries(
    organisationLanguages.map(lang => [
      lang.isoCode.toUpperCase(),
      lang.label
    ])
  )
  const defaultLanguage = organisationLanguages[0]?.isoCode?.toUpperCase() || 'EN'
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage)

  const initialFormValues = Object.fromEntries(
    organisationLanguages.map(lang => [
      lang.isoCode.toLowerCase(),
      {}
    ])
  )
  const [formValues, setFormValues] = useState(initialFormValues)

  const [formKey, setFormKey] = useState(0)

  const page = state.page || {}
  const isOpen = state.overlayOpen && page && !_.isEmpty(page)
  const isSavingPage = state.isSavingPage

  const {
    id = '',
    title = '',
    content = {},
    schema = []
  } = page

  useEffect(() => {
    const contentValues = Object.fromEntries(
      organisationLanguages.map(lang => [
        lang.isoCode.toLowerCase(),
        content?.[lang.isoCode.toLowerCase()] || {}
      ])
    )
    setFormValues(contentValues)
  }, [content, organisationLanguages])

  const handleClose = () => {
    toggleOverlay({ status: CLOSE })
  }

  const handleLanguageChange = (event) => {
    const form = dispatch(getFormValues(formName))
    if (form) {
      setFormValues(prev => ({
        ...prev,
        [selectedLanguage.toLowerCase()]: form
      }))
    }
    setSelectedLanguage(event.target.value)
    setFormKey(prev => prev + 1)
  }

  const handleFormChange = (values) => {
    setFormValues(prev => ({
      ...prev,
      [selectedLanguage.toLowerCase()]: values
    }))
  }

  const handleSubmit = async (values) => {
    const fields = {
      content: {
        ...formValues,
        [selectedLanguage.toLowerCase()]: values
      }
    }

    try {
      await updatePage({ id, values: fields })
      toastSuccess({})
    } catch (error) {
      toastError({ error })
    }
  }

  const initialValues = formValues[selectedLanguage.toLowerCase()] || {}

  return (
    <Drawer
      heading={title}
      open={isOpen}
      anchor={'right'}
      onClose={handleClose}
      width={'60vw'}
    >
      <>
        <LanguageSelector>
          <InputLabel id="language-select-label">Language</InputLabel>
          <Select
            labelId="language-select-label"
            value={selectedLanguage}
            onChange={handleLanguageChange}
            label="Language"
          >
            {Object.entries(languageObject).map(([code, label]) => (
              <MenuItem key={code} value={code}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </LanguageSelector>

        <PageForm
          key={formKey}
          editing
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onChange={handleFormChange}
          enableReinitialize
          destroyOnUnmount={false}
          keepDirtyOnReinitialize
        >
          <FormBody
            schema={parseSchema(schema)}
            layout={getLayout(schema)}
          />
          <SubmitContainer>
            <FormSubmit
              onClick={() => dispatch(submit(formName))}
              disabled={isSavingPage}
            >{translations('Update')}</FormSubmit>
          </SubmitContainer>
        </PageForm>
      </>
    </Drawer>
  )
}

const parseSchema = (schema) => {
  const parsedSchema = []
  for (const field of schema) {
    const parsedField = { ...field }
    const maxLength = _.get(field, 'props.maxLength')
    if(maxLength) {
      parsedField.props.validate = [validators.maxLength(maxLength, 'Field')]
    }
    parsedSchema.push(parsedField)
  }
  return parsedSchema
}

const getLayout = (schema) => {
  const keys = _.map(schema, 'id')
  const layout = _.map(keys, key => `${_.camelCase(key)}:12`)
  return layout
}

export default PageOverlay