import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { translations } from '../../../config'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { selectors as formViewSelectors } from '../../../store/modules/formViews'
import { actions as reportDetailsActions, selectors as reportDetailsSelectors } from '../../../store/modules/reportDetails'
import { useSelector } from 'react-redux'
import Editable from '../../../components/Editable'
import ReportForm from './components/ReportForm/ReportForm'
import { Grid } from '@material-ui/core'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import toastService from '../../../services/toastService'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { withStyles } from '@material-ui/core/styles'
import { useExistingReportErrors } from '../hooks'
import style from './style'
import ReportScreenBase from './NewReportBeta'
import Button from '../../../components/Button'
import { showValidationSummary } from '../../CaseManagement/CaseSearchScreen/CaseSearchScreen/utils'
import getValidationErrors from '../../../helpers/getValidationErrors'

const EditReportScreen = Editable(({ classes, location, match }) => {
  const dispatch = useDispatch()
  const reportId = _.get(match, 'params.id')
  const followUpNotificationId = _.get(match, 'params.notificationId')
  const { isWorkspace, selectedOrganisation, skipReadOnlyView, existingReport, formSchemaId, isReportEditable } = useSelector(state => ({
    isWorkspace: authSelectors.isLoggedIntoWorkspace(state),
    selectedOrganisation: authSelectors.getSelectedOrganisation(state),
    skipReadOnlyView: platformSelectors.getHasSkipReportReadOnlyViewEnabled(state),
    existingReport: reportDetailsSelectors.getExistingReport(state),
    formSchemaId: formViewSelectors.getSelectedFormSchemaId(state),
    isReportEditable: reportDetailsSelectors.getIsReportEditable(state)
  }))

  const [formState, setFormState] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [initialValues, setInitialValues] = useState()
  const [organisationDetails, setOrganisationDetails] = useState()
  const [productDetails, setProductDetails] = useState()
  const [isReadOnly, setIsReadOnly] = useState(false)
  const [isNewReport, setIsNewReport] = useState(false)

  useExistingReportErrors(existingReport)

  useEffect(() => {
    if (reportId) {
      if (reportId !== 'new') {
        setIsReadOnly(!skipReadOnlyView)
      } else {
        setIsNewReport(true)
      }
    }
  }, [reportId])

  useEffect(() => {
    const product = _.get(location, 'state.product')
    if (product) {
      setProductDetails(product)
    }
    if (!isWorkspace && selectedOrganisation) {
      setOrganisationDetails(selectedOrganisation.details)
    }
    }, [location])

  const onFormStateChange = useCallback((state) => {
    setFormState(state)
  },[setFormState])


  const onSuccess = (props) => {
    const extReportId = _.get(props, 'response.report.extReportId')
    dispatch(push('/report-management'))
    toastService.action({
      type: 'success',
      message: translations('New Report Beta - Success submitting report', { reportId: extReportId }),
      autoHideDuration: 6000
    })
  }

  const onError = (error) => {
    toastService.action({
      type: 'error',
      message: translations('New Report Beta - Error submitting report', { error: error.message }),
      autoHideDuration: 6000
    })
  }

  const onSaveDraftSuccess = (props = {}) => {
    const { report: { extReportId } = {} } = props
    dispatch(push('/report-management'))
    toastService.action({
      type: 'success',
      message: translations('New Report Beta - Draft Success', { reportId: extReportId }),
      autoHideDuration: 6000
    })
  }

  const onSaveDraftError = (error) => {
    toastService.action({
      type: 'error',
      message: translations('New Report Beta - Error saving draft', { error }),
      autoHideDuration: 6000
    })
  }
  const onLoadExisting = (e) => {
    setIsLoading(true)
    const file = e.target.files[0]
    dispatch(reportDetailsActions.loadExistingReport({ file, formSchemaId })).then(result => {
      const messageNumber = _.get(result, 'messagenumb')
      if (_.isNil(messageNumber) || _.isEmpty(messageNumber)) {
        const omitted = _.omit(result, ['messagenumb'])
        setInitialValues(omitted)
      } else {
        setInitialValues(result)
      }
      setIsLoading(false)
    })
      .catch(error => {
        setIsLoading(false)

        const validationErrors = getValidationErrors(error)

        if (validationErrors) {
          showValidationSummary()({
            validationErrors,
            validationStatus: 'INVALID'
          })
        }

        toastService.action({
          type: 'error',
          message: translations('New Report Beta - Error loading existing', { error: error.message }),
          autoHideDuration: 6000
        })
      })
  }

  const ReportFormComponent = (
    <ReportForm
      isWorkspace={isWorkspace}
      onFormStateChange={onFormStateChange}
      onSuccess={onSuccess}
      onError={onError}
      onSaveDraftSuccess={onSaveDraftSuccess}
      onSaveDraftError={onSaveDraftError}
      initialValues={initialValues}
      organisationDetails={organisationDetails}
      productDetails={productDetails}
      isReadOnly={isReadOnly}
      isNewReport={isNewReport}
    />
  )
  const UpdateButton = (<>
      {isReadOnly && isReportEditable && !skipReadOnlyView && (
      <Button
        onClick={() => setIsReadOnly(false)}
        raised
        variant='contained'
        buttonType='primary'
        style={{ marginRight: 20 }}
      >
        {translations('Update')}
      </Button>
    )}
  </>
  )

  const ReportFormRender = !isLoading && (
    <div>
      {followUpNotificationId ? (
        <Grid container alignItems="center">
          <Grid item xs={12} sm={12} md={8} lg={6} container justifyContent="center">
            <div className={classes.formContainer}>{ReportFormComponent}</div>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.scrollableGridItem}>
            <div className={classes.formContainer}>{ReportFormComponent}</div>
          </Grid>
        </Grid>
      )}
    </div>
  )
  
  return <ReportScreenBase mode={'edit'} ReportFormRender={ReportFormRender} formState={formState} onLoadExisting={onLoadExisting} updateButton={UpdateButton}/>
})

export default withStyles(style)(EditReportScreen)
