import _ from 'lodash'

/**
 * Retrieves validation errors from the provided error object.
 * It checks multiple paths for error messages and returns the first found error.
 * If no errors are found, an empty array is returned.
 * 
 * The API logic defined within the following APIs has slightly different error responses.
 * Some logic is shared with APIs that third party systems use and don't want to change the error 
 * response for.
 * 
 * /api/v1/reports/:reportId/validate
 * /api/v1/reports/load
 *
 * @param {Object} error - The error object containing potential validation errors.
 * @returns {Array} - An array of validation error messages or an empty array if none are found.
 */
const getValidationErrors = (error) => {
    const errorPaths = [
        'meta.properties.error.Messages',
        'meta.properties.errors',
        'error.validationErrors'
    ];

    const result = _.find(errorPaths, path => _.get(error, path))
    
    return result ? _.get(error, result) : []
}

export default getValidationErrors
