import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import _ from 'lodash'
import { DataTableRow, DataTableCell } from '../../../../../components/DataTable'
import { translations } from '../../../../../config'
import moment from 'moment'
import Button from '../../../../../components/Button'
import ButtonLink from '../../../../../components/ButtonLink'

const LineListingRow = ({entry}) => {
  const {
    id,
    name,
    reportType,
    formViewName,
    category,
    author,
    createdAt,
    updatedAt,
    handleExport,
    handleLineListingClick
  } = entry

  return (
    <DataTableRow>
      <DataTableCell compact>
        <ButtonLink onClick={handleLineListingClick}>{name}</ButtonLink>
      </DataTableCell>
      <DataTableCell compact align={'center'} nowrap>{reportType}</DataTableCell>
      <DataTableCell compact align={'center'}>{formViewName}</DataTableCell>
      <DataTableCell compact align={'center'}>{category}</DataTableCell>
      <DataTableCell compact align={'center'}>{author}</DataTableCell>
      <DataTableCell compact align={'center'} nowrap>{moment(createdAt).format('DD/MM/YYYY HH:mm')}</DataTableCell>
      <DataTableCell compact align={'center'} nowrap>{moment(updatedAt).format('DD/MM/YYYY HH:mm')}</DataTableCell>
      <DataTableCell compact align={'right'}>
        <Button buttonType='primary' onClick={handleExport}>
          {translations('Line Listing Management - Export Button')}
        </Button>
      </DataTableCell>
    </DataTableRow>
  )
}

export default LineListingRow